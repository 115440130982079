import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-awesome-styled-grid';

import PageLayout from '../Layout';
import RegistrationForm from './Parts/RegistrationForm';

const Registration = ({ user = {} }) => {
  // TODO: Use Navigation router to route back
  const handleSignUp = (_userData) => {
    if (_userData && !(_userData.error || _userData.errors)) {
      window.location.reload();
    }
  };

  return <PageLayout user={user}>
      <Container style={{paddingTop: '1em'}}>
        <RegistrationForm onSignUp={handleSignUp}/>
      </Container>
  </PageLayout>;
};

Registration.propTypes = {
  user: PropTypes.object,
};

Registration.defaultProps = {
  user: {},
};

export default Registration;
