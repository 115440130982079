import React, { createContext, useContext, useEffect, useReducer, useState} from 'react';

export const NewsDataContext = createContext(null);
export const NewsCommentsContext = createContext(null);
export const CommentsContext = createContext(null);
export const CommentRepliesContext = createContext(null);
export const CommentsDispatchContext = createContext(null);

// We only load root comments to start out.
// Children will be added later
const format_store = (initialComments) => {
  let store = {};
  store["root"] = {}; // Comments at the root of the News are stored here
  store["rootIds"] = initialComments.map(comment => comment.id); // An ordered list of Comments at the root
  store["replyIdsMap"] = {}; // A map for all comments to find their replies
  store["replies"] = {}; // All comments that are replies are stored here

  initialComments.forEach(comment => {
    store["root"][comment.id] = { ...comment, expanded: false };
    store["replyIdsMap"][comment.id] = [];
  });

  return store;
}

export const NewsCommentsProvider = ({ news, initialComments, children }) => {
  const [newsCommentsStore, dispatch] = useReducer(newsCommentsReducer, initialComments, format_store);
  const [newsComments, setNewsComments] = useState([]);
  const [comments, setComments] = useState({});
  const [replies, setReplies] = useState({});

  useEffect(() => {
    // Update News Comments with their sub replies
    setNewsComments(newsCommentsStore.rootIds.map((id) => ({
      ...newsCommentsStore.root[id],
      replyIds: [...newsCommentsStore.replyIdsMap[id]] // An ordered list of root comment's replies
    })));

    // Update Replies with their new sub replies
    setReplies(Object.fromEntries(
      Object.entries(newsCommentsStore.replies).map(
        ([id, reply]) => [id,
          ({ ...reply,
            replyIds: [...newsCommentsStore.replyIdsMap[id]] // An ordered list of the reply's replies
          })])
    ));
  }, [newsCommentsStore])

  useEffect(() => {
    setComments({
      ...Object.fromEntries(newsComments.map((comment) => [comment.id, comment])),
      ...replies,
    })
  }, [replies, newsComments])

  return (
    <NewsDataContext.Provider value={news}>
      <NewsCommentsContext.Provider value={newsComments}>
        <CommentsContext.Provider value={comments}>
          <CommentRepliesContext.Provider value={replies}>
            <CommentsDispatchContext.Provider value={dispatch}>
              {children}
            </CommentsDispatchContext.Provider>
          </CommentRepliesContext.Provider>
        </CommentsContext.Provider>
      </NewsCommentsContext.Provider>
    </NewsDataContext.Provider>
  );
}

// Store: { news: {...}, comments: [...], replies: {...}}
// Action: { comment: object }
const newsCommentsReducer = (store, action) => {
  switch (action.type) {
    case 'added': {
      const newComment = { ...action.comment, expanded: false };

      const parentId = action.comment.parent_id;
      const parentType = action.comment.parent_type;
      const parent = parentType === 'Comment' && (store.root[parentId] || store.replies[parentId]);
      
      const shouldAdd = parentType === 'Content' || !!parent;

      return shouldAdd ? {
        ...store,
        root: {
          ...store.root,
          ...(!newComment.is_reply ? { [newComment.id]: { ...store.root[newComment.id], ...newComment, expanded: false  } } : {})
        },
        rootIds: [
          ...store.rootIds,
          ...(!newComment.is_reply ? [newComment.id] : [])
        ],
        replies: {
          ...store.replies,
          ...(newComment.is_reply ? {
            [newComment.id]: { ...newComment, expanded: false }
          } : {})
        }, // Add reply to reply dictionary
        replyIdsMap: {
          ...store.replyIdsMap,
          ...(newComment.is_reply ? {
            [newComment.parent_id]: Array.from(new Set([...store.replyIdsMap[newComment.parent_id], newComment.id])).sort((a, b) => 
            Date.parse(a.created_at) - Date.parse(b.created_at)),
            [newComment.id]: [], // Also initialize self replies
          } : { [newComment.id]: [], }),
        },
      } : store;
    }
    case 'changed': {
      if (store.rootIds.includes(action.comment.id)) { // Root comment
        return {
          ...store,
          root: {
            ...store.root,
            [action.comment.id]: {...store.root[action.comment.id], ...action.comment}
          }
        }
      } else {
        let shouldUpdate = true;

        // Reject if it's not been loaded
        if (!Object.keys(store.replies).includes(action.comment.id)) {
          shouldUpdate = false;
        }
        const updatedComment = action.comment;

        // Reject if there is no parent loaded
        const parent = shouldUpdate ? (store.root[updatedComment.parent_id] || store.replies[updatedComment.parent_id]) : undefined;
        if (!parent) {
          shouldUpdate = false;
        }

        // Reject if parent has not been expanded
        if (parent && !parent.expanded) {
          shouldUpdate = false;
        }

        if (shouldUpdate) {
          return {
            ...store,
            replies: {
              ...store.replies,
              [updatedComment.id]: {...store.replies[updatedComment.id], ...updatedComment}
            }
          }
        } else {
          return store;
        }
      }
    }
    case 'deleted': {
      const updatedRoot = store.root;
      const updatedReplies = store.replies;
      const updatedReplyIdsMap = store.replyIdsMap;

      if (!action.comment.is_reply) { // Root comment
        delete updatedRoot[action.comment.id];
        delete updatedReplyIdsMap[action.comment.id]; // Remove reply map entry
      }

      if (action.comment.is_reply) {
        if (Object.keys(updatedReplies).includes(action.comment.id)) {
          delete updatedReplies[action.comment.id];
        }
        if (Object.keys(updatedReplyIdsMap).includes(action.comment.id)) {
          delete updatedReplyIdsMap[action.comment.id];
        }
      }
      
      return {
        ...store,
        ...(action.comment.is_reply ? {} : { rootIds: store.rootIds.filter((id) => id !== action.comment.id)}),
        root: updatedRoot,
        replyIdsMap: updatedReplyIdsMap,
        replies: updatedReplies,
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export const useNewsData = () => {
  return useContext(NewsDataContext);
}

export const useNewsCommentsData = () => {
  return useContext(NewsCommentsContext);
}

export const useRepliesData = () => {
  return useContext(CommentRepliesContext);
}

export const useCommentsData = () => {
  return useContext(CommentsContext);
}

export const useCommentsDispatch = () => {
  return useContext(CommentsDispatchContext);
}
