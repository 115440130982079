import { useEffect } from 'react';
import { useDeleteData } from '../../../hooks/useData';

export const useLogout = () => {
  const [_loading, signedOut, signUserOut] = useDeleteData('/users/sign_out');

  const handleSignOut = (e = null) => {
    try {
      e.preventDefault();
    } catch (error) {}
    signUserOut();
  }

  useEffect(() => {
    // TODO: Do proper routing
    if(signedOut) {
      window.location.reload();
    }
  }, [signedOut])

  return handleSignOut;
}
