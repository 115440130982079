import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  position: relative;
  padding: 4px;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 2px;
    background: ${ props => props.secondary ? `${props.theme.colors.gray}`: 'linear-gradient(270deg, #00F 0%, #F00 100%)'} ; 
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
  }
`;

Box.propTypes = {
  secondary: PropTypes.bool,
}

Box.defaultProps = {
  secondary: false,
}

export default Box;