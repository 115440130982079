import React, { createContext, useContext, useReducer} from 'react';

export const LatestNewsContext = createContext(null);
export const ArchiveNewsContext = createContext(null);
export const TopPickNewsContext = createContext(null);
export const UserNewsContext = createContext(null);
export const NewsDispatchContext = createContext(null);

export const NewsProvider = ({ initialNews, children }) => {
  const [newsStore, dispatch] = useReducer(newsReducer, initialNews);

  const latestNews  = newsStore.news.filter(n => !n.archive);
  const archiveNews = newsStore.news.filter(n => n.archive);
  const topPickNews = newsStore.top_picks;
  const userNews = newsStore.news;

  return (
    <LatestNewsContext.Provider value={latestNews}>
      <ArchiveNewsContext.Provider value={archiveNews}>
        <TopPickNewsContext.Provider value={topPickNews}>
          <UserNewsContext.Provider value={userNews}>
            <NewsDispatchContext.Provider value={dispatch}>
              {children}
            </NewsDispatchContext.Provider>
          </UserNewsContext.Provider>
        </TopPickNewsContext.Provider>
      </ArchiveNewsContext.Provider>
    </LatestNewsContext.Provider>
  );
}

const newsReducer = (newsStore, action) => {
  switch (action.type) {
    case 'added': {
      return {
        ...newsStore,
        news: [...newsStore.news, ...action.news]
      }
    }
    case 'changed': {
      const newsIndex = newsStore.news.findIndex((item) => item.id === action.news.id);
      const topPickIndex = newsStore.top_picks.findIndex((item) => item.id === action.news.id);

      let newsList = newsStore.news;
      let topPickList = newsStore.top_picks;

      if ( (newsIndex < 0) && (topPickIndex < 0)) {
        return newsStore;
      } else {
        if (newsIndex >= 0) {
          newsList[newsIndex] = { ...newsList[newsIndex], ...action.news };
        }

        if (topPickIndex >= 0) {
          topPickList[topPickIndex] = { ...topPickList[topPickIndex], ...action.news };
        }

        return {
          ...newsStore,
          news: newsList,
          top_picks: topPickList,
        }
      }
    }
    case 'removed': {
      return {
        ...newsStore,
        news: newsStore.filter(n => n.id !== action.news.id),
        top_picks: newsStore.top_picks.filter(n => n.id !== action.news.id)
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export const useLatestNews = () => {
  return useContext(LatestNewsContext);
}

export const useArchiveNews = () => {
  return useContext(ArchiveNewsContext);
}

export const useTopPickNews = () => {
  return useContext(TopPickNewsContext);
}

export const useUserNews = () => {
  return useContext(UserNewsContext);
}

export const useNewsDispatch = () => {
  return useContext(NewsDispatchContext);
}
