import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { darkTheme } from '../../theme';
import { CurrentUserProvider } from '../Users/Contexts/UserContext';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';
import { ToastProvider } from '../Navigation/Contexts/ToastContext';

const PageLayout = ({ user, children }) => {
  return  <ThemeProvider theme={darkTheme}>
    <ToastProvider>
      <CurrentUserProvider user={user}>
          <Header />
          {children}
          <Footer />
      </CurrentUserProvider>
    </ToastProvider>
  </ThemeProvider>;
};

PageLayout.propTypes = {
  user: PropTypes.object
};

PageLayout.defaultProps = {
  user: {}
}

export default PageLayout;
