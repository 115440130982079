import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

const fontSizeForVariant = (props) => {
  if(props.primary) {
    return `
      font-weight:  ${props => props.theme.typography.weight.bold};
      font-size: ${props => props.theme.typography.size.header_4}px;
      line-height: ${props => props.theme.typography.size.header_4 * 1.5}px;
    `
  } else {
    return `
    font-weight:  ${props => props.theme.typography.weight.regular};
    font-size: ${props => props.theme.typography.size.body}px;
    line-height: ${props => props.theme.typography.size.body * 1.5}px;
  `
  }
}

export const StyledLink = styled.a`
  text-decoration: none;
  background: 'none';
  color: ${props => props.primary ?  props.theme.colors.gray : props.theme.colors.blue};

  font-family: ${props => props.theme.typography.type.default};
  ${props => fontSizeForVariant(props)}

  &:hover, &:active {
    text-decoration: none;
    background: 'none';
    cursor: pointer;
    color: ${props => props.primary ?  props.theme.colors.gray : props.theme.colors.blue};
  }

  &:visited, &:any-link:visited {
    text-decoration: none;
    background: 'none';
    color: ${props => props.primary ?  props.theme.colors.gray : props.theme.colors.blue};
  }
`;

const InternalLink = ({ label, ...rest }) => <StyledLink {...rest}>{label}</StyledLink>;

InternalLink.propTypes = {
  /**
   * The link body
  */
  label: PropTypes.string.isRequired,
  /**
   * Indicator of whether to use primary styling or secondary
   */
  primary: PropTypes.bool,
};

InternalLink.defaultProps = {
  primary: true,
};


export default InternalLink;
