import React from 'react';
import { useFloating } from '@floating-ui/react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PlaceHolder = styled.div`
  height: ${props => props.height}px;
`;
PlaceHolder.propTypes = {
  height: PropTypes.number.isRequired,
};
PlaceHolder.defaultProps = {};

const Floater = styled.div`
  width: 100vw;
`
const FloatingDiv = ({ height, children }) => {
  const {refs, floatingStyles } = useFloating({
    placement: 'top',
  });

  return (
    <>
      <PlaceHolder ref={refs.setReference} height={height}></PlaceHolder>
      <Floater ref={refs.setFloating} style={floatingStyles}>{ children }</Floater>
    </>
  )
};

FloatingDiv.propTypes = {
  height: PropTypes.number.isRequired,
};

FloatingDiv.defaultProps = {};

export default FloatingDiv;
