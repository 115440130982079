import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'
import Caption from '../../../components/Caption';

const ProfilePic = styled.img`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const Username = styled.h4`
  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_4}px;
  line-height: ${props => props.theme.typography.size.header_4 * 1.5}px;

  color: ${props => props.theme.colors.yellowAlt };
  margin: 0px;
  padding: 0px;
`

const UserTagLine = styled.div`
  flex-shrink: 0;
`;

const StyledDiv = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
`;

const AuthorCard = ({username, picture, joined_at }) => <StyledDiv>
  <ProfilePic src={picture} />
  <UserTagLine>
    <Username>{username}</Username>
    <Caption>Joined {joined_at}</Caption>
  </UserTagLine>
</StyledDiv>

AuthorCard.propTypes = {
  username: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  joined_at: PropTypes.string.isRequired
};

AuthorCard.defaultProps = {};

export default AuthorCard;
