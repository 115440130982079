import Arrow_Down from './svgs/Arrow_Down.svg';
import Arrow_External from './svgs/Arrow_External.svg';
import Arrow_Left from './svgs/Arrow_Left.svg';
import Arrow_Right from './svgs/Arrow_Right.svg';
import Arrow_Up from './svgs/Arrow_Up.svg';
import Bookmark_Selected from './svgs/Bookmark_Selected.svg';
import Bookmark_Unselected from './svgs/Bookmark_Unselected.svg';
import Chevron_Down from './svgs/Chevron_Down.svg';
import Chevron_Left from './svgs/Chevron_Left.svg';
import Chevron_Right from './svgs/Chevron_Right.svg';
import Chevron_Up from './svgs/Chevron_Up.svg';
import Close from './svgs/Close.svg';
import Comments from './svgs/Comments.svg';
import Edit from './svgs/Edit.svg';
import Menu from './svgs/Menu.svg';
import Moon_Full from './svgs/Moon_Full.svg';
import More from './svgs/More.svg';
import Profile from './svgs/Profile.svg';
import Share from './svgs/Share.svg';
import Sign_Out from './svgs/Sign_Out.svg';
import Sun_Full from './svgs/Sun_Full.svg';
import Visible from './svgs/Visible.svg';
import URL_Link from './svgs/URL_Link.svg';

const iconSvg = (iconName) => {
  const name = iconName.toLowerCase();

  switch (name) {
    case 'arrow_down':
      return Arrow_Down;
    case 'arrow_external':
      return Arrow_External;
    case 'arrow_left':
        return Arrow_Left;
    case 'arrow_right':
        return Arrow_Right;
    case 'arrow_up':
      return Arrow_Up;
    case 'bookmark_selected':
      return Bookmark_Selected;
    case 'bookmark_unselected':
      return Bookmark_Unselected;
    case 'chevron_down':
      return Chevron_Down;
    case 'chevron_left':
      return Chevron_Left;
    case 'chevron_right':
      return Chevron_Right;
    case 'chevron_up':
      return Chevron_Up;
    case 'close':
        return Close;
    case 'comments':
      return Comments;
    case 'edit':
      return Edit;
    case 'menu':
      return Menu;
    case 'moon_full':
      return Moon_Full;
    case 'more':
      return More;
    case 'profile':
      return Profile;
    case 'share':
      return Share;
    case 'sign_out':
      return Sign_Out;
    case 'sun_full':
      return Sun_Full;
    case 'visible':
      return Visible;
    case 'url_link':
      return URL_Link;
    default:
      break;
  }
};

export default iconSvg;
