import "@hotwired/turbo-rails";
import "@fontsource/poppins";
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import ReactOnRails from 'react-on-rails';
Turbo.session.drive = false

// Enable Turbo
ReactOnRails.setOptions({ turbo: true });

import GlobalStyle from '../pages/GlobalStyle';
import Errors from '../pages/Errors';
import News from '../pages/News';
import NewsContent from '../pages/News/NewsContent';
import NewsComments from "../pages/News/NewsComments";
import Profile from '../pages/Users/Profile';
import Login from '../pages/Users/Login';
import Register from '../pages/Users/Register';
import About from '../pages/About';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import ContentSubmission from '../pages/ContentSubmission';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  GlobalStyle,
  Errors,
  News,
  NewsContent,
  NewsComments,
  Profile,
  Login,
  Register,
  About,
  ContentSubmission,
  Terms,
  Privacy
});
