import PropTypes from 'prop-types';
import React from 'react';

import { TabPanel } from '../react-tabs';
import styled from 'styled-components';

const StyledPanel = styled(TabPanel)`
  color:  ${props => props.theme.colors.text};
`;

StyledPanel.tabsRole = 'TabPanel';

export default StyledPanel;