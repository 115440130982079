import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

export const StyledLink = styled.a`
  text-decoration: none;
  background: 'none';
  cursor: pointer;
  color: ${props => props.active ?  props.theme.colors.gray : props.theme.colors.text};

  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_3}px;
  line-height: ${props => props.theme.typography.size.header_3 * 1.5}px;

  &:hover {
    text-decoration: none;
    background: 'none';
    color: ${props => props.theme.colors.yellowAlt };
  }
`;

const NavLink = ({ children, ...rest }) => <StyledLink {...rest}>{children}</StyledLink>;

NavLink.propTypes = {
  /**
   * Whether to use the active state
  */
  active: PropTypes.bool,
};

NavLink.defaultProps = {
  active: false
};

export default NavLink;
