import React from 'react';
import { Container, Row, Col } from 'react-awesome-styled-grid';

import Logo from '../../components/Logo';
import Disclaimer from '../../components/Disclaimer';
import Divider from '../../components/Divider';
import styled from 'styled-components';
import PlainLink from '../../components/Link/PlainLink';
import { useToasts } from './Contexts/ToastContext';
import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import Toast from '../../components/Toast';

const FloatingFooter = styled.div`
  position: fixed;
  width: 100%;
  bottom:0px;
  background: ${ props => props.theme.colors.background};
`
const Footer = (props) => {
  const toasts = useToasts();
  const floating = useFloating({
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
    ],
  });

  return <>
  <Container >
    <Row ref={floating.refs.setFloating} style={floating.floatingStyles}>{toasts.map(msg => <Col xs={6}><Toast data={msg}/></Col>)}</Row>
  </Container>
  <FloatingFooter ref={floating.refs.setReference}>
      <Divider />
      <Container style={{paddingTop: '1em'}}>
      <Row style={{padding: '15px 0px'}}>
        <Col justify='center' align='start' md={4}>
            <Row justify='space-between' style={{width: '100%'}}>
              <Col><Disclaimer>Copyright © 2023 - Unfated.Ai</Disclaimer></Col>
              <Col><PlainLink href="/terms" style={{textDecoration: 'none'}}><Disclaimer>Terms of Service</Disclaimer></PlainLink></Col>
              <Col><PlainLink href="/privacy" style={{textDecoration: 'none'}}><Disclaimer>Privacy Policy</Disclaimer></PlainLink></Col>
            </Row>
        </Col>
        <Col justify='end' align='flex-end' md={2} >
            <Row justify='space-between' style={{width: '100%'}}>
              <Col><PlainLink href="https://twitter.com/UnfatedAI" style={{textDecoration: 'none'}}><Disclaimer>Twitter</Disclaimer></PlainLink></Col>
              <Col><PlainLink href="https://www.tiktok.com/@unfatedai" style={{textDecoration: 'none'}}><Disclaimer>TikTok</Disclaimer></PlainLink></Col>
              <Col><PlainLink href="https://www.instagram.com/unfatedai" style={{textDecoration: 'none'}}><Disclaimer>Instagram</Disclaimer></PlainLink></Col>
            </Row>
        </Col>
      </Row>
    </Container>
  </FloatingFooter>
  </>;
};

Footer.propTypes = {};

export default Footer;
