import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

export const StyledLink = styled.a`
  text-decoration: none;
  background: 'none';
  cursor: pointer;
  color: ${props => props.active ?  props.theme.colors.gray : props.theme.colors.text};

  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_3}px;
  line-height: ${props => props.theme.typography.size.header_3 * 1.5}px;

  color: ${props => props.theme.colors.yellowAlt };

  img {
    width: 24px;
    height: 24px;
    margin-bottom: -4px;
    display: inline-block;
  }
`;

const UserLink = ({ user, ...rest }) => <StyledLink {...rest}>
  <img src={user.thumbnail} /> <span>{user.name}</span>
</StyledLink>;

UserLink.propTypes = {
  /**
   * The user who's link is being displayed.
  */
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired
  }),
};

UserLink.defaultProps = {
  id: -1,
};

export default UserLink;
