import React from 'react';
import { Container, Row, Col, Hidden } from 'react-awesome-styled-grid';

import Box from '../../../components/Box';
import { useCollapse } from 'react-collapsed';
import { IconButton } from '../../../components/Icons';
import styled from 'styled-components';
import { useCurrentUserData } from '../Contexts/UserContext';
import { NewsProvider } from '../../News/Contexts/NewsContext';
import NewsList from '../../News/Parts/NewsList';

const FlexRow = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: center;
`;

const UserUpvotedNews = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const user = useCurrentUserData();

  const initialNews = { news: user.recent_voted_contents, top_picks: []};

  return <Col xs={6}>
    <Box>
      <FlexRow>
        <h3>Your Upvoted News</h3>
        <IconButton name={isExpanded ? 'chevron_down' : 'chevron_up'} {...getToggleProps()}></IconButton>
      </FlexRow>
    </Box>
    <Row {...getCollapseProps()}>
      <NewsProvider initialNews={initialNews}>
        <NewsList type={'user'}/>
      </NewsProvider>
    </Row>
  </Col>;
};

UserUpvotedNews.propTypes = {};

UserUpvotedNews.defaultProps = {};

export default UserUpvotedNews;