import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Caption from '../../../components/Caption';
import { useCurrentUserData } from '../Contexts/UserContext';
import { Col, Row } from 'react-awesome-styled-grid';
import Divider from '../../../components/Divider';
import IconLink from '../../../components/Link/IconLink';
import PlainLink from '../../../components/Link/PlainLink';
import { useLogout } from '../hooks/useLogout';
import { useModal } from '../../../hooks/useModal';
import { Highlight, Description, ProfilePic, FlexRow } from '../Cards/ProfilePieces';
import ProfileEditForm from './ProfileEditForm';
import AccountSecurityForm from './AccountSecurityForm';

const ProfileBar = () => {
  const user = useCurrentUserData();
  const [modal, toggleModal] = useModal(<ProfileEditForm />, "User settings");
  const [securityModal, toggleSecurityModal] = useModal(<AccountSecurityForm />, "Account Security");

  const handleLogout = useLogout();

  return <Col xs={6}>
    {modal}
    {securityModal}
    <Row><h3><Highlight>{user.username}</Highlight>, Welcome to your Profile</h3></Row>
    <br />
    <FlexRow>
      <ProfilePic src={user.avatar_url} />
      <div>
        <Caption>Member since {user.since}</Caption>
        <Description>{user.description}</Description>
        <IconLink name='edit' onClick={toggleModal}> Edit Profile</IconLink>
      </div>
    </FlexRow>
    <Divider secondary style={{margin: '10px 0px'}}/>
    <Row>
      <PlainLink active href='#'>Your points ({user.points})</PlainLink>
    </Row>
    <Divider secondary style={{margin: '10px 0px'}}/>
    <Row>
      <Col xs={6}><PlainLink onClick={toggleSecurityModal} style={{margin: '5px 0px'}}>Account Security</PlainLink></Col>
      <Col xs={6}><PlainLink href='/terms' style={{margin: '5px 0px'}}>Terms of Service</PlainLink></Col>
      <Col xs={6}><PlainLink href="mailto:help@unfated.ai" style={{margin: '5px 0px'}}>Help</PlainLink></Col>
    </Row>
    <Divider secondary style={{margin: '10px 0px'}}/>
    <Row>
      <IconLink name='sign_out' href='#' onClick={handleLogout}> Sign Out</IconLink>
    </Row>
  </Col>;
};

ProfileBar.propTypes = {};

ProfileBar.defaultProps = {};

export default ProfileBar;
