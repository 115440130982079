import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-awesome-styled-grid';

import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import InternalLink from '../../../components/Link/InternalLink';

import { usePostData, usePutData } from '../../../hooks/useData';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField';

const CommentForm = ({ edit, reply, newsId, commentId, commentBody, onSubmit, onCancel }) => {
  const createUrl = reply ? `/comments/${commentId}/reply` : `/news/${newsId}/comment`;
  const editUrl = `/comments/${commentId}`;

  const [_loading, commentData, createComment] = usePostData(createUrl, { isForm: true });
  const [_loading_update, updatedCommentData, updateComment] = usePutData(editUrl, { isForm: true });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    var urlEncodedData = new URLSearchParams(formData);

    if (edit) {
      updateComment(urlEncodedData.toString());
    } else {
      createComment(urlEncodedData.toString());
    }
  }

  useEffect(() => {
    if (commentData) {
      onSubmit(commentData);
    }
  }, [commentData]);

  useEffect(() => {
    if (updatedCommentData) {
      onSubmit(updatedCommentData);
    }
  }, [updatedCommentData]);

  const [body, setBody] = useState(commentBody);

  return <Col xs={6}>
      <br />
      <Row justify='center'>
        <Col  xs={6} align='center'><h3>Comment</h3></Col>
        <Col  xs={2} ><Divider /></Col>
      </Row>
      <br />
      <Row justify='center'>
        <form id="new_comment" onSubmit={handleSubmit}>
        {/* style={{width: '350px', height: '150px'}} */}
          <Col>
            <InputField name='comment[body]' label='Your response' text  value={body} onChange={(e) => setBody(e.target.value)} />
            <br />
            <Row>
              <Button type='submit' name="commit" small>Post Comment</Button>
            </Row>
            <br />
            <Row justify='center'>
              <InternalLink label="Cancel" onClick={onCancel} />
            </Row>
          </Col>
        </form>
      </Row>
    </Col>;
};

CommentForm.propTypes = {
  edit: PropTypes.bool,
  reply: PropTypes.bool,
  newsId: PropTypes.string,
  commentId: PropTypes.string,
  commentBody: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

CommentForm.defaultProps = {
  edit: false,
  reply: false,
  newsId: null,
  commentId: null,
  commentBody: '',
  onSubmit: () => {},
  onCancel: () => {},
};

export default CommentForm;