import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-awesome-styled-grid';
import {useFloating, FloatingArrow, arrow, offset, useDismiss, useInteractions, useListNavigation} from '@floating-ui/react';
import styled, { useTheme } from 'styled-components';
// import PartyBox from '../PartyBox';
import Icon from '../Icons';

const MenuBtn = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0px 0px;
  margin: 0px 0px;
  color: ${props => props.theme.colors.text };
  font-weight: ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height: ${props => props.theme.typography.size.body * 1.5}px;
`;

export const DropdownDivider = styled.div`
  display: block;
  width: 100%;
  height: 2px;
  margin: 4px auto;
  background: ${props => props.theme.colors.gray};
`;

const MenuContent = styled.div`
  background: ${props => props.theme.colors.black };
  border-radius: 4px;
  border: 2px solid  ${props => props.theme.colors.yellow };
  padding: 10px 12px;
  text-align: left;

  color: ${props => props.theme.colors.text };

  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height: ${props => props.theme.typography.size.body * 1.5}px;

  a {
    text-decoration: none;
    background: none;
    cursor: pointer;
    display: block;
    padding: 2px 0px;
    color: ${props => props.active ?  props.theme.colors.gray : props.theme.colors.text};
  }
  
  button {
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0px 0px;
    margin: 0px 0px;
    color: ${props => props.theme.colors.text };
    font-weight:  ${props => props.theme.typography.weight.regular};
    font-size: ${props => props.theme.typography.size.body}px;
    line-height: ${props => props.theme.typography.size.body * 1.5}px;
  }

  a:hover, a:active, button:hover, button:active {
    text-decoration: underline;
    background: none;
    color: ${props => props.theme.colors.yellowAlt };
  }
`;

const ARROW_HEIGHT = 7;
const GAP = 2;

export const MenuItemTypes = {
  divider: 0,
  link: 1,
  callback: 2,
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const DropdownMenu = ({ icon, label, items }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const theme = useTheme();

  const arrowRef = useRef(null);
  const {refs, floatingStyles, context} = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(ARROW_HEIGHT + GAP),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const listRef = useRef([]);
 
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });
 
  const dismiss = useDismiss(context);
 
  const {getReferenceProps, getFloatingProps, getItemProps} = useInteractions([
    dismiss,
    listNavigation
  ]);

  return (
    <>
     <MenuBtn ref={refs.setReference} {...getReferenceProps()} onClick={() => setIsOpen(!isOpen)}><Icon name={icon} />{label}</MenuBtn>
      { isOpen && <MenuContent ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          <FloatingArrow ref={arrowRef} context={context} fill={theme.colors.yellow} />
         {items.map((item, index) => {
           // ITEM: [type, (link|callback)?, (content|element)?]
            const type = item[0];
            const isLink = type === MenuItemTypes.link;
            const isCallback = type === MenuItemTypes.callback;

            const itemProps = {
              key: `key-${getRandomInt(1000)}-${index}`,
                // Make these elements focusable using a roving tabIndex.
              tabIndex: (activeIndex === index ? 0 : -1),
              ref: (node) => {
                  listRef.current[index] = node;
                },
              ...getItemProps()
            };

            return isLink ? <a {...itemProps} href={item[1]}>{item[2]}</a> : isCallback ? <button {...itemProps} onClick={item[1]}>{item[2]}</button> : <DropdownDivider />;
          })}
        </MenuContent> }
    </>
  )
};

DropdownMenu.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
};

DropdownMenu.defaultProps = {
  icon: '',
  label: '',
  items: [],
};

export default DropdownMenu;
