
import React from 'react';
import styled from 'styled-components'

export const ProfilePic = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 12px;
  border: 1px dashed #830198;
  margin-right: 10px;
`;

export const Description = styled.p`
  color: ${props => props.theme.colors.yellowAlt };
  margin: 0px;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Highlight = styled.em`
  color: ${props => props.theme.colors.yellowAlt };
`;
