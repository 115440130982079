import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

const Disclaimer = styled.span`
  color: ${props => props.theme.colors.text};

  font-style: italic;

  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.small};
  font-size: ${props => props.theme.typography.size.disclaimer}px;
  line-height: ${props => props.theme.typography.size.disclaimer * 1.5}px;
`;

export default Disclaimer;