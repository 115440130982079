import { useState, useEffect } from 'react';

export const useNavigationTracker = () => {
  const [prevLink, setPrevLink] = useState();

  useEffect(() => {
    // Update path tracking
    const previousPath = window.sessionStorage.getItem("prevPath");
    const previousTobePath = window.sessionStorage.getItem("prevTobePath");
    const currPath = window.location.pathname;

    let prevLink = previousPath;

    const pageChanged = (previousTobePath !== currPath);
    // Update the prevPath/currPath if there was an actual change
    if (pageChanged) {
      window.sessionStorage.setItem("prevPath", previousTobePath);
      window.sessionStorage.setItem("prevTobePath", currPath);
      prevLink = previousTobePath;
    }

    // Set previous page navigation if it's possible
    if (!!prevLink && (prevLink !== 'null')) {
      setPrevLink(prevLink)
    }
  }, []);

  return prevLink;
}
