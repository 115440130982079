import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-awesome-styled-grid';

import Button from '../../../components/Button';
import SectionHeader from '../../../components/SectionHeader';

import { usePutData } from '../../../hooks/useData';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField';
import styled from 'styled-components';
import { useCurrentUserData } from '../Contexts/UserContext';
import { Highlight, Description, ProfilePic, FlexRow } from '../Cards/ProfilePieces';
import Caption from '../../../components/Caption';
import PlainLink from '../../../components/Link/PlainLink';
import { useToastAdd } from '../../Navigation/Contexts/ToastContext';

const ProfileEditForm = ({ onEditProfile, errors }) => {
  const formRef = useRef();
  const [loading, userData, updateUser] = usePutData('/users', { isForm: true });
  const [profileEditErrors, setProfileEditErrors] = useState(errors);
  const user = useCurrentUserData();
  const addToast = useToastAdd();

  const handleSubmit = (e) => {
    setProfileEditErrors({});
    e.preventDefault();

    const formData = new FormData(e.target);
    var urlEncodedData = new URLSearchParams(formData);

    updateUser(urlEncodedData.toString());
    formData
  }

  useEffect(() => {
    if (userData) {
      if (userData.errors) {
        setProfileEditErrors(userData.errors)
      } else {
        if(formRef) {
          formRef.current.reset();
          setProfileEditErrors({});
          addToast("Profile updated!", 5000);
        }
        onEditProfile(userData);
      }
    }
  }, [userData]);

  return <Col xs={6}>
      <Row justify='center'>
        <Col xs={4} sm={3} md={3} lg={2} align='center'>
          <SectionHeader label='Your Profile' />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={5} sm={4} md={4} lg={3}  align='center'>
          <h4>Hi, <Highlight>{user.username}</Highlight></h4>
          <FlexRow>
            <ProfilePic src={user.avatar_url} />
            <div>
              <Caption>Member since {user.since}</Caption>
              <div><PlainLink active href='#'>Your points ({user.points})</PlainLink></div>
              <Description>{user.description}</Description>
            </div>
        </FlexRow>
        </Col>
      </Row>
      <br />
      <Row justify='center'>
        <Col xs={5} sm={4} md={4} lg={4} align='center'>
          <form id="new_user" onSubmit={handleSubmit} ref={formRef}>
            <InputField errors={profileEditErrors['description']} text
              name='user[description]'  placeholder={user.description}  label='Update your Profile Description' type='text' aria-required required />
            <br />
            <InputField errors={profileEditErrors['current_password']} name='user[current_password]' type='password' label='Current Password'  />
            <br />
            <Row>
              <Button type='submit' name="commit">Update Profile</Button>
            </Row>
          </form>
        </Col>
      </Row>
    </Col>;
};

ProfileEditForm.propTypes = {
  onEditProfile: PropTypes.func,
  errors: PropTypes.object,
};

ProfileEditForm.defaultProps = {
  onEditProfile: () => {},
  errors: {},
};

export default ProfileEditForm;