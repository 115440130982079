import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import { darkTheme } from '../../theme';

const GlobalStyling = createGlobalStyle`
html, body {
  margin: 0;
  padding-bottom: 100px;
  background: ${ props => props.theme.colors.background };
  color: ${ props => props.theme.colors.text };

  font-family: "Poppins", sans-serif;
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height: ${props => props.theme.typography.size.body * 1.5}px;
}

*:focus-visible {
  outline: solid ${ props => props.theme.colors.yellow };
}

.danger {
  color: ${ props => props.theme.colors.red };
}

ul {
  padding: 0px;
  padding-left: 1em;
  margin: 0px;
}

h1 {
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_1}px;
  line-height: ${props => props.theme.typography.size.header_1 * 1.5}px;
  margin: 0;
}

h2 {
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_2}px;
  line-height: ${props => props.theme.typography.size.header_2 * 1.5}px;
  margin: 0;
}

h3 {
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_3}px;
  line-height: ${props => props.theme.typography.size.header_3 * 1.5}px;
  margin: 0;
}

h4 {
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_4}px;
  line-height: ${props => props.theme.typography.size.header_4 * 1.5}px;
  margin: 0;
}

pre {
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height: ${props => props.theme.typography.size.body * 1.5}px;
  text-wrap: wrap;
  font-family: "Poppins", sans-serif;
}

::placeholder {
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height:${props => props.theme.typography.size.body  * 1.5}px;
}

input {
  box-sizing: border-box;
  height: 45px;
  color: ${props => props.theme.colors.text};
  border-radius: 8px;
  padding: 12px 24px;
  font-weight: ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height:${props => props.theme.typography.size.body  * 1.5}px;
  background: ${props => props.theme.colors.black};
  border: 2px solid ${props => props.theme.colors.gray};

  &:active, &:hover, &:active {
    border: 2px solid ${props => props.theme.colors.blue};
  }
}

textarea {
  box-sizing: border-box;
  color: ${props => props.theme.colors.text};
  border-radius: 8px;
  padding: 12px 24px;
  font-weight: ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height:${props => props.theme.typography.size.body  * 1.5}px;
  background: ${props => props.theme.colors.black};
  border: 2px solid ${props => props.theme.colors.gray};

  &:active, &:hover, &:active {
    border: 2px solid ${props => props.theme.colors.blue};
  }
}

input[type=checkbox] {
	-moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	outline: none;
	content: none;
  border: none;
  height: 25px;
  width: 25px;
  margin: 10px 0px;
  padding: 0;
  cursor: pointer;
}

input[type=checkbox]:before {
    content: "✓";
    font-size: 24px;
    color: transparent !important;
    background: ${props => props.theme.colors.black};
    display: block;
    border: 2px solid ${props => props.theme.colors.gray};
    border-radius: 2px;
}

input[type=checkbox]:checked:before {
  border: 2px solid ${props => props.theme.colors.text} !important;
	color: ${props => props.theme.colors.text} !important;
}


label {
  padding: 0px 24px;
  font-weight: ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height:${props => props.theme.typography.size.body * 1.5}px;
}

.modalOverlay {
  position: fixed;
  inset: 0px;
  border: none;
}

.modalContent {
  position: absolute;
  inset: 0px;
  padding: 24px;
  overflow-y: auto;
  background-color: ${props => props.theme.colors.background};
}

@media screen and (min-width: ${ props => props.theme.awesomegrid.container.sm }em) {
  .modalContent {
    inset: 0px 0px 0px 50vw;
    border-left: 2px solid ${ props => props.theme.colors.yellow };
  }
}
`

// later in your app
const GlobalStyle = (props) => { 
  return <ThemeProvider theme={darkTheme}>
  <React.Fragment>
    <GlobalStyling />
  </React.Fragment>
</ThemeProvider> 
}
GlobalStyle.propTypes = {};

export default GlobalStyle;
