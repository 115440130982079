import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';

import Box from '../../components/Box';

import PageLayout from '../Layout';
import styled from 'styled-components';
import ProfileBar from './Parts/ProfileBar';
import UserComments from './Parts/UserComments';
import UserBookmarks from './Parts/UserBookmarks';
import UserUpvotedNews from './Parts/UserUpvotedNews';

const SideBar = styled(Col)`
@media screen and (min-width: ${ props => props.theme.awesomegrid.container.sm }em) {
  padding-left: 2em;
  align-items: flex-start;
  flex-direction: row;
}
`;

const Profile = ({ user }) => {
  return <PageLayout user={user}>
      <Container style={{paddingTop: '1em'}}>
        <Row reverse={['xs']}>
          <Col xs={6} sm={4}>
            {/* <Row><UserComments /></Row> */}
            <br />
            <Row><UserBookmarks /></Row>
            <br />
            <Row><UserUpvotedNews /></Row>
          </Col>
          <SideBar xs={6} sm={2}>
            <Row><ProfileBar /></Row>
          </SideBar>
        </Row>
      </Container>
  </PageLayout>;
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Profile;
