import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

const StyledLink = styled.a`
  text-decoration: none;
  background: 'none';
  color: ${props => props.secondary ? props.theme.colors.yellow : props.theme.colors.blue};

  &:hover {
    text-decoration: none;
    background: 'none';
    color: ${props => props.secondary ? props.theme.colors.yellow : props.theme.colors.blue};
  }
`;

const StyledHeading = styled.h4`
  color: ${props => props.theme.colors.text};
  text-align: left;

  font-weight:  ${props => props.theme.typography.weight.bold };
  font-size: ${props => props.secondary ? props.theme.typography.size.body : props.theme.typography.size.header_4}px;
  line-height: ${props => (props.secondary ? props.theme.typography.size.body : props.theme.typography.size.header_4) * 1.5}px;
`;


export const ExternalLink = ({ label, src, children, ...rest }) => label ? <StyledHeading {...rest}>
  {label} {' '} <StyledLink {...rest} target="_blank">({src})</StyledLink>
</StyledHeading> : <>{children} <StyledLink {...rest} target="_blank">({src})</StyledLink></>;

ExternalLink.propTypes = {
  /**
   * Link label
   */
  label: PropTypes.string,
  /**
   * The external source
   */
  src: PropTypes.string.isRequired,
  /**
   * Indicator of whether to use the yellow color instead of normal heighlight
   */
  secondary: PropTypes.bool,
};

ExternalLink.defaultProps = {
  label: null,
  secondary: false,
}

export default ExternalLink;
