import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import { useNavigationTracker } from '../../hooks/useNavigationTracker';

import SectionHeader from '../../components/SectionHeader';
import IconLink from '../../components/Link/IconLink';
import ExternalLink from '../../components/Link/ExternalLink';
import Box from '../../components/Box';

import PageLayout from '../Layout';

const BackLink = styled(Row)`
  margin: 16px auto;
`;

const NewsSummary = styled(Box)`
  margin-top: 1em;
  padding: 1em;
`;


const NewsContent = ({ user, news }) => {
  const prevLink = useNavigationTracker();

  return <PageLayout user={user}>
    <Container style={{paddingTop: '1em'}}>
      { prevLink ? <BackLink><IconLink name='arrow_left' href={prevLink}>Return to Previous Page</IconLink></BackLink> : '' }
      <Row><h2><ExternalLink href={news.link} src={news.source}>{news.title}</ExternalLink></h2></Row>
      <Row>
        <NewsSummary>
          <h4>TLDR; Summary</h4>
          <div dangerouslySetInnerHTML={{__html: news.summary}} />
        </NewsSummary>
      </Row>
    </Container>
  </PageLayout>;
};

NewsContent.propTypes = {
  user: PropTypes.object,
  news: PropTypes.object.isRequired
};
NewsContent.defaultProps = {
  user: null,
}

export default NewsContent;
