import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row, Col, Hidden, Visible } from 'react-awesome-styled-grid';

import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import TabList from '../../components/TabList';
import TabPanel from '../../components/TabPanel';
import SectionHeader from '../../components/SectionHeader';
import NewsList from './Parts/NewsList';

import { NewsProvider } from './Contexts/NewsContext';
import PageLayout from '../Layout';
import PartyLink from '../../components/PartyLink';

const News = ({ user, initialNews }) => {
  return <PageLayout user={user}>
    <NewsProvider initialNews={initialNews}>
      <Container style={{paddingTop: '1em'}}>
        <Row>
          <Col sm={6} md={4}>
            <Tabs>
              <TabList>
                <Visible xs sm>
                  <PartyLink href='/submit_content' style={{ marginRight: '1.5em' }}>Submit Content</PartyLink>
                </Visible>
                <Tab >Archives</Tab>
                <Tab >New News</Tab>
                <Hidden xs sm>
                  <PartyLink href='/submit_content'>Submit Content</PartyLink>
                </Hidden>
                <Visible xs sm><Tab >Top Picks</Tab></Visible>
              </TabList>
              <TabPanel style={{paddingTop: '10px'}}>
                <NewsList type={'archive'}/>
              </TabPanel>
              <TabPanel style={{paddingTop: '10px'}}>
                <NewsList type={'latest'}/>
              </TabPanel>
              <Hidden md lg>
                <TabPanel style={{paddingTop: '10px'}}>
                  <NewsList type={'top_picks_tab'}/>
                </TabPanel>
              </Hidden>
            </Tabs>
          </Col>
          <Hidden xs sm>
            <Col md={2} style={{ alignSelf: 'start', paddingTop: '8px', paddingLeft: '27px'}}>
              <Row>
                <Col xs={5}><SectionHeader gradient={false} label={"Editor Picks"} /></Col>
                <Col xs={1}></Col>
              </Row>
              <NewsList type={'top_picks'}/>
            </Col>
          </Hidden>
        </Row>
      </Container>
    </NewsProvider>
  </PageLayout>;
};

News.propTypes = {
  user: PropTypes.object,
  initialNews: PropTypes.shape({
    news: PropTypes.array.isRequired,
    top_picks: PropTypes.array.isRequired,
  }).isRequired
};

News.defaultProps = {
  user: {}
}

export default News;
