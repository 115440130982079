import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-awesome-styled-grid';

import Button from '../../../components/Button';
import SectionHeader from '../../../components/SectionHeader';

import { usePutData } from '../../../hooks/useData';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField';
import styled from 'styled-components';
import { useCurrentUserData } from '../Contexts/UserContext';
import { Highlight, Description, ProfilePic, FlexRow } from '../Cards/ProfilePieces';
import Caption from '../../../components/Caption';
import PlainLink from '../../../components/Link/PlainLink';
import { useToastAdd } from '../../Navigation/Contexts/ToastContext';

const AccountSecurityForm = ({ onAccountProfile, errors }) => {
  const formRef = useRef();
  const [loading, userData, updateUser] = usePutData('/users', { isForm: true });
  const [profileEditErrors, setProfileEditErrors] = useState(errors);
  const user = useCurrentUserData();
  const addToast = useToastAdd();
  const [doNotSell, setDoNotSell] = useState(user.do_not_sell);

  const handleSubmit = (e) => {
    setProfileEditErrors({});
    e.preventDefault();

    const formData = new FormData(e.target);
    var urlEncodedData = new URLSearchParams(formData);

    updateUser(urlEncodedData.toString());
    formData
  }

  useEffect(() => {
    if (userData) {
      if (userData.errors) {
        setProfileEditErrors(userData.errors)
      } else {
        if(formRef) {
          formRef.current.reset();
          setProfileEditErrors({});
          addToast("Account updated!", 5000);
        }
        onAccountProfile(userData);
      }
    }
  }, [userData]);

  return <Col xs={6}>
      <Row justify='center'>
        <Col xs={4} sm={3} md={3} lg={2} align='center'>
          <SectionHeader label='Account Security' />
        </Col>
      </Row>
      <br />
      <Row justify='center'>
        <Col xs={5} sm={4} md={4} lg={3} align='center'>
          <form id="user_update" onSubmit={handleSubmit} ref={formRef}>
            <InputField errors={profileEditErrors['do_not_sell']} checkbox checked={doNotSell}  onChange={() => setDoNotSell(!doNotSell)} type='checkbox' name='user[do_not_sell]' label='Do not sell my information' />
            <br />
            <InputField errors={profileEditErrors['current_password']} name='user[current_password]' aria-required  required type='password' label='Password (we need your current password to confirm your changes)'  />
            <Row>
              <Button type='submit' name="commit">Update Profile</Button>
            </Row>
          </form>
        </Col>
      </Row>
    </Col>;
};

AccountSecurityForm.propTypes = {
  onAccountProfile: PropTypes.func,
  errors: PropTypes.object,
};

AccountSecurityForm.defaultProps = {
  onAccountProfile: () => {},
  errors: {},
};

export default AccountSecurityForm;