import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-awesome-styled-grid';

import PageLayout from '../Layout';
import ContentForm from './Parts/ContentForm';
import styled from 'styled-components';

const Hero = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


  background: ${props => props.theme.colors.blue };
  padding: 82px 0px;

  h3 {
    display: flex;
    width: 776px;
    flex-direction: column;
  }
`;

const ContentSubmission = ({ user }) => {
  return <PageLayout user={user}>
      <Hero>
        <h1>Submit Content and Earn Points</h1>
        <h3>If their is content out on the web and you believe it belongs here, submit it below.</h3>
      </Hero>
      <Container>
        <ContentForm />
      </Container>
  </PageLayout>;
};

ContentSubmission.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ContentSubmission;
