import React, { useState, useRef } from 'react';
import { Row, Hidden } from 'react-awesome-styled-grid';

import NavLink from '../../../components/Link/NavLink';
import Icon, { IconButton } from '../../../components/Icons';
import UserLink from '../../../components/Link/UserLink';
import UserLoginForm from '../../Users/Parts/LoginForm';
import { useModal } from '../../../hooks/useModal';
import { useCurrentUserData, useUserDispatch } from '../../Users/Contexts/UserContext';
import { useLogout } from '../../Users/hooks/useLogout';
import {useFloating, offset, useDismiss, useInteractions, useListNavigation} from '@floating-ui/react';
import Dropdown, {DropdownMenu, DropdownDivider} from '../../../components/Dropdown';
import styled from 'styled-components';

const MenuBtn = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0px 0px;
  margin: 0px 0px;
  color: ${props => props.theme.colors.text };
  font-weight: ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height: ${props => props.theme.typography.size.body * 1.5}px;
`;

const Menu = (props) => {
  const userDispatch = useUserDispatch();
  const user = useCurrentUserData();
  const userLoggedIn = !!(user && user.id);

  const handleUserLogin = (userData) => {
    userDispatch({type: 'login', user: userData });
    window.location.reload();
  };

  const [modal, toggleModal] = useModal(<UserLoginForm onSignIn={handleUserLogin} />, "User Login Form", userLoggedIn);

  const handleLogout = useLogout();

  const [activeIndex, setActiveIndex] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // useFloating

  const {refs, floatingStyles, context} = useFloating({
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
    middleware: [
      offset(14),
    ],
  });

  const listRef = useRef([]);
 
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });
 
  const dismiss = useDismiss(context);
 
  const {getReferenceProps, getFloatingProps, getItemProps} = useInteractions([
    dismiss,
    listNavigation
  ]);

  return <Row align='center'>
    {modal}
    <Hidden lg xl>
        <MenuBtn ref={refs.setReference} {...getReferenceProps()} onClick={() => setIsMenuOpen(!isMenuOpen) }><Icon name='menu' /></MenuBtn>
        { isMenuOpen && <div style={{...floatingStyles, transform: 'none', right: '0px', willChange: 'none'}}  ref={refs.setFloating} {...getFloatingProps()}><DropdownMenu gradient >
            <a href='/about' tabIndex={(activeIndex === 0 ? 0 : -1)} ref={(node) => {
                listRef.current[0] = node;
              }}
              {...getItemProps()}
              >About Us</a>
            <DropdownDivider />
            <a href='/terms'
              tabIndex={(activeIndex === 1 ? 0 : -1)} ref={(node) => {
                listRef.current[1] = node;
              }}
              {...getItemProps()}
              >Terms of Service</a>
            <a href='/privacy'
              tabIndex={(activeIndex === 2 ? 0 : -1)} ref={(node) => {
                listRef.current[2] = node;
              }}
              {...getItemProps()}
              >Privacy</a>
            <DropdownDivider />
            { userLoggedIn && <UserLink
              user={{ id: user.id, name: user.username, thumbnail: user.avatar_url }} href='/users/profile'
              tabIndex={(activeIndex === 3 ? 0 : -1)} ref={(node) => {
                listRef.current[3] = node;
              }}
              {...getItemProps()} /> }
            { userLoggedIn && <IconButton name='sign_out' onClick={handleLogout}
              tabIndex={(activeIndex === 4 ? 0 : -1)} ref={(node) => {
                listRef.current[4] = node;
              }}
              >Sign Out</IconButton>}
            { !userLoggedIn && <IconButton name='profile' onClick={toggleModal} 
                tabIndex={(activeIndex === 3 ? 0 : -1)} ref={(node) => {
                  listRef.current[3] = node;
                }}
              > Sign In or Create an Account</IconButton>}
        </DropdownMenu></div>}
    </Hidden>
    <Hidden xs sm md>
        <NavLink href='/about' style={{paddingRight: '24px'}}>About Us</NavLink>
        {userLoggedIn ? <UserLink user={{ id: user.id, name: user.username, thumbnail: user.avatar_url }} href='/users/profile' /> : <NavLink href='#' onClick={(e) => { e.preventDefault(); toggleModal(); }}><Icon name='profile' heading/> Sign in</NavLink> }
    </Hidden>
  </Row>;
};

Menu.propTypes = {};

export default Menu;
