import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

import Icon from '../Icons';

const StyledLink = styled.a`
  text-decoration: none;
  background: 'none';
  cursor: pointer;
  color: ${props => props.active ?  props.theme.colors.yellowAlt : props.theme.colors.text};

  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.caption}px;
  line-height: ${props => props.theme.typography.size.caption * 1.5}px;

  ${props => props.padRight ? 'padding-right: 8px;' : ''}

  &:hover {
    text-decoration: none;
    background: 'none';
    color: ${props => props.theme.colors.yellowAlt };
  }
`;

const StyledIcon = styled(Icon)`
  width: 17px;
  height: 17px;
`;

const IconLink = ({ children, name, ...rest }) => <StyledLink {...rest}>
  <StyledIcon name={name} />{children}
</StyledLink>;

IconLink.propTypes = {
  /**
   * The name of the Icon to use
  */
 name: PropTypes.string.isRequired,
 /**
  * The link href
  */
 href: PropTypes.string,
  /**
   * Whether to use the active state
  */
  active: PropTypes.bool,
    /**
   * Indicator of whether we should add padding around link
  */
  padRight: PropTypes.bool,
};

IconLink.defaultProps = {
  href: '#modal',
  padRight: false,
  active: false
};

export default IconLink;
