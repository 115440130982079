import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import Modal from 'react-modal';

import IconLink from '../components/Link/IconLink';
import { IconButton } from '../components/Icons';

// Use Resetter (useResetter) for special cases where modal needs to be remove
export const useModal = (content, contentLabel, reset) => {
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState("");

  const handleToggleModal = () => {
    setShowModal(!showModal);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    if (reset) {
      setShowModal(false);
    }
  }, [reset])

  useEffect(() => {
    if(showModal) {
      Modal.setAppElement(document.getElementById('container'));
      setModal(
        <Modal
          isOpen={showModal}
          overlayClassName={'modalOverlay'}
          className={'modalContent'}
          onRequestClose={handleCloseModal}
          contentLabel={contentLabel}>
          <Container>
            <Row>
              <Col align='flex-start'>
                <IconButton
                  name='close'
                  onClick={(e) => {
                    e.preventDefault();
                    handleToggleModal();
                  }}></IconButton>
              </Col>
            </Row>
            <Row>{content}</Row>
          </Container>
        </Modal>
      )
    } else {
      setModal("");
    }
  }, [showModal]);

  return [modal, handleToggleModal];
}
