import React, { useState } from "react";
import { useCommentsDispatch } from "../Contexts/NewsCommentsContext";
import { useModal } from "../../../hooks/useModal";
import CommentForm from "../Parts/CommentForm";
import { useResetter } from "../../../hooks/useResetter";



// const [resetModal, triggerReset] = useResetter(false);

export const useCommentForm = ({newsId = null, commentId = null, commentBody = ''}) => {
  const commentDispatch = useCommentsDispatch();
  const [editMode, setEditMode] = useState(false);
  const [replyMode, setReplyMode] = useState(false);
  const [resetModal, triggerReset] = useResetter(false);
  
  const handleFormSubmission = (commentData) => {
    if (commentData && !commentData.errors) {
      if (editMode) {
        commentDispatch({ type: 'changed', comment: commentData });
      } else {
        commentDispatch({ type: 'added', comment: commentData });
      }
      triggerReset(true);
    }
  }
  
  const [formModal, toggleModal] = useModal(
    <CommentForm
      edit={editMode} reply={replyMode} newsId={newsId} commentId={commentId} commentBody={editMode ? commentBody : '' }
      onSubmit={handleFormSubmission} onCancel={() => triggerReset(true)} />, 'Comment Form', resetModal);

  const addNewsComment = () => {
    setEditMode(false);
    setReplyMode(false);
    toggleModal();
  }

  const addReplyComment = () => {
    setEditMode(false);
    setReplyMode(true);
    toggleModal();
  }

  const editComment = () => {
    setReplyMode(false);
    setEditMode(true);
    toggleModal();
  }

  return { formModal, addNewsComment, addReplyComment, editComment }
}