import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'


export const Divider = styled.hr`
  min-height: ${props => props.tall ? '8px' : '2px'};
  background: ${props => props.secondary ? props.theme.colors.gray : (props.gradient ? 
    'linear-gradient(270deg, #0000FF -4.15%, #FF0000 99.91%)' : props.theme.colors.red) };
  border: none;
  width: 100%;
  margin: 0px;
`;

Divider.propTypes = {
  /**
   * Whether to use tall version of the Divider
  */
  tall: PropTypes.bool,
  /**
   * Whether to gradient version is enabled or not
  */
  gradient: PropTypes.bool,
  /**
   * Whether to use the secondary gray background instead of the gradient.
   * When specified, it overrides the gradient prop.
  */
  secondary: PropTypes.bool
};

Divider.defaultProps = {
  tall: false,
  gradient: true,
  secondary: false,
};

export default Divider;
