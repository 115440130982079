import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-awesome-styled-grid';

import { useNewsCommentsData, useNewsData } from '../Contexts/NewsCommentsContext';
import NewsCard, { Modes } from '../Cards/NewsCard';
import Button from '../../../components/Button';
import CommentCard from '../Cards/CommentCard';
import { useCurrentUserData } from '../../Users/Contexts/UserContext';
import { useToastSetter } from '../../../hooks/useToast';
import { useCommentForm } from '../hooks/useCommentFormModal';
// import { useNewsCommentsUpdates } from '../hooks/useNewsCommentsUpdates';


const CommentsList = () => {
  const user = useCurrentUserData();
  const userLoggedIn = !!(user && user.id);
  useToastSetter(!userLoggedIn ? "Create an account to comment, vote, and bookmark content across our feeds. Sign Up Now, It’s free." : null);
  const news = useNewsData();
  const newsComments = useNewsCommentsData();
  const { formModal, addNewsComment } = useCommentForm({ newsId: news.id })
  // useNewsCommentsUpdates(news.id);

  return <Row>
      {formModal}
      <Col xs={6}>
        <Row>
          <Col md={5}><NewsCard {...news} mode={Modes.heading}></NewsCard></Col>
          <Col md={1} align='center' justify='center'>{ userLoggedIn ? <Button label="Leave a Comment" onClick={addNewsComment} /> : ''}</Col>
        </Row>
      </Col>
      <Col xs={6}>
        {newsComments.map(comment => <CommentCard key={`comment_${comment.id}`} {...comment} />)} 
      </Col>
    </Row>;
};

CommentsList.propTypes = {};

export default CommentsList;
