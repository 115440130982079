import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

export const DropdownDivider = styled.div`
  display: block;
  width: 75%;
  padding: 4px 12px;
  border-top: 2px solid ${props => props.theme.colors.gray};
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownMenu = styled.div`
  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height: ${props => props.theme.typography.size.body * 1.5}px;
  display: block;
  position: absolute;
  background-color: ${props => props.theme.colors.background};
  padding: 12px 16px;
  z-index: 1;
  border-radius: 4px;
  right: 0px;
  left: 0px;

  @media screen and (min-width: ${ props => props.theme.awesomegrid.container.sm }em) {
    margin-top: 0em;
    min-width: 283px;
  }

  @media (max-width: ${ props => props.theme.awesomegrid.container.sm }em) {
    min-width: 100vw;
    margin-top: 1em;
    position: fixed;
  }

  ${ props => props.gradient && `
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      border-radius: 0px;
      padding: 2px 0px;
      background: linear-gradient(270deg, #00F 0%, #F00 100%); 
      -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude; 
    }

    @media screen and (min-width: ${ props => props.theme.awesomegrid.container.sm }em) {
      border-radius: 4px;
      padding: 2px;
    }
  `}

  a {
    text-decoration: none;
    background: none;
    cursor: pointer;
    display: block;
    padding: 8px 24px;
    color: ${props => props.active ?  props.theme.colors.gray : props.theme.colors.text};
  }

  button {
    text-decoration: none;
    background: none;
    cursor: pointer;
    display: block;
    padding: 8px 24px;
    color: ${props => props.active ?  props.theme.colors.gray : props.theme.colors.text};
  }

  a:hover, a:active {
    text-decoration: underline;
    background: none;
    color: ${props => props.theme.colors.yellowAlt };
  }
`;

DropdownMenu.propTypes = {
  visible: PropTypes.bool,
  left: PropTypes.bool,
  /**
   * Whether to use the active state
  */
  gradient: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  visible: false,
  left: false,
  gradient: false,
};

export default Dropdown;
