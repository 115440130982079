import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-awesome-styled-grid';

import PageLayout from '../Layout';
import LoginForm from './Parts/LoginForm';

const Login = ({ user = {} }) => {
  // TODO: Use Navigation router to route back
  const handleLogin = (_userData) => {
    if (_userData && !(_userData.error || _userData.errors)) {
      window.location.reload();
    }
  };

  return <PageLayout user={user}>
      <Container style={{paddingTop: '1em'}}>
        <LoginForm onSignIn={handleLogin}/>
      </Container>
  </PageLayout>;
};

Login.propTypes = {
  user: PropTypes.object,
};

Login.defaultProps = {
  user: {},
};

export default Login;
