import { useState, useEffect } from "react";
import { useNewsDispatch } from "../Contexts/NewsContext";
import { usePostData } from "../../../hooks/useData";
import { useCurrentUserChecker } from "../../Users/hooks/useCurrentUser";

export const useNewsUpvote = () => {
  const dispatch = useNewsDispatch();
  const ensureLoggedIn = useCurrentUserChecker();

  const [url, setUrl] = useState();
  const [_voting, voteData, upvote] = usePostData(url);

  const handleUpvote = (id) => {
    setUrl(`/news/${id}/vote`);
  }

  useEffect(() => {
    if (url) {
      ensureLoggedIn();
      upvote(true); // Send payload a.k.a initiate request
    }
  }, [url])

  useEffect(() => {
    if(voteData && voteData.success) {
      dispatch({ type: 'changed', news: voteData.news });
      setUrl(); // Cleanup request for next request
      upvote(false);
    }
  }, [voteData]);

  return handleUpvote;
}

export const useNewsBookmark = () => {
  const dispatch = useNewsDispatch();
  const ensureLoggedIn = useCurrentUserChecker();

  const [url, setUrl] = useState();
  const [_bookmarking, bookmarkData, bookmark] = usePostData(url);

  const handleBookmark = (id) => {
    setUrl(`/news/${id}/bookmark`);
  }

  useEffect(() => {
    if (url) {
      ensureLoggedIn();
      bookmark(true); // Send payload a.k.a initiate request
    }
  }, [url])

  useEffect(() => {
    if(bookmarkData && bookmarkData.success) {
      dispatch({ type: 'changed', news: bookmarkData.news });
      setUrl(); // Cleanup request for next request
      bookmark(false);
    }
  }, [bookmarkData]);

  return handleBookmark;
}


export const useNewsReport = () => {
  const dispatch = useNewsDispatch();
  const ensureLoggedIn = useCurrentUserChecker();

  const [url, setUrl] = useState();
  const [_reporting, reportData, report] = usePostData(url);

  const handleReport = (id) => {
    setUrl(`/news/${id}/report`);
  }

  useEffect(() => {
    if (url) {
      ensureLoggedIn();
      report(true); // Send payload a.k.a initiate request
    }
  }, [url])

  useEffect(() => {
    if(reportData && reportData.success) {
      dispatch({ type: 'changed', news: reportData.news });
      setUrl(); // Cleanup request for next request
      report(false);
    }
  }, [reportData]);

  return handleReport;
}

export const useNewsUndoReport = () => {
  const dispatch = useNewsDispatch();
  const ensureLoggedIn = useCurrentUserChecker();

  const [url, setUrl] = useState();
  const [_reporting, reportData, report] = usePostData(url);

  const handleReport = (id) => {
    setUrl(`/news/${id}/unreport`);
  }

  useEffect(() => {
    if (url) {
      ensureLoggedIn();
      report(true); // Send payload a.k.a initiate request
    }
  }, [url])

  useEffect(() => {
    if(reportData && reportData.success) {
      dispatch({ type: 'changed', news: reportData.news });
      setUrl(); // Cleanup request for next request
      report(false);
    }
  }, [reportData]);

  return handleReport;
}
