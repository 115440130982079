

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { IconButton } from '../Icons';
import { useToastRemove } from '../../pages/Navigation/Contexts/ToastContext';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ToastDiv = styled(FlexRow)`
  color: ${props => props.theme.colors.black };
  padding: 12px;
  border-radius: 8px;
  background: var(--dark-core-theme-yellow, ${props => props.theme.colors.yellow });

  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height: ${props => props.theme.typography.size.body * 1.5}px;
  align-items: center;

  button {
    padding-left: 1em;
    color: ${props => props.theme.colors.black };
  }
`;

const Toast = ({ data, ...rest }) => {
  const removeToast = useToastRemove();

  return <ToastDiv  {...rest}>
    <div dangerouslySetInnerHTML={{__html: data}} ></div>
    <IconButton name={'close'} onClick={() => removeToast(data)} />
  </ToastDiv>
}

Toast.propTypes = {
  data: PropTypes.string,
}

Toast.defaultProps = {
  data: ''
}

export default Toast;
