import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const PartyLink = styled.a`
  text-decoration: none;
  border-radius: 8px;
  border: 2px solid ${props => props.theme.colors.yellow };
  background: ${props => props.theme.colors.black };

  color: ${props => props.theme.colors.text };
  margin: 0px;
  padding: 8px 16px;
  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height: ${props => props.theme.typography.size.body * 1.5}px;
  height: ${props => props.theme.typography.size.body * 1.5}px;

  &:hover, &:active {
    text-decoration: none;
    background: var(--core-gradient, linear-gradient(270deg, #00F 0%, #F00 100%));
  }
`;

PartyLink.propTypes = {}

PartyLink.defaultProps = {}

export default PartyLink;
