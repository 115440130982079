import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

export const Sizes = {
  wide: 'wide',
  short: 'short'
};

const cssForSize = (props) => {
  if(props.small) {
    return `
      max-height: fit-content;
      ${props.size === Sizes.wide ? 'width: 100%;' : 'width: fit-content;' }
      padding: ${props.size === Sizes.wide ? '0px' : '8px 16px' };
      font-weight:  ${props.theme.typography.weight.bold};
      font-size: ${props.theme.typography.size.body}px;
      line-height: ${props.theme.typography.size.body * 1.5}px;
    `;
  } else {
    return `
      max-height: fit-content;
      ${props.size === Sizes.wide ? 'width: 100%;' : 'width: fit-content;'}
      font-weight:  ${props.theme.typography.weight.bold};
      font-size: ${props.theme.typography.size.header_4}px;
      line-height: ${props.theme.typography.size.header_4 * 1.5}px;
    `;
  }
}


const StyledButton = styled.button`
  min-height: ${props => props.small ? '37px': '48px'};
  color: ${props => props.primary ? props.theme.colors.text: props.theme.colors.gray};

  ${props => cssForSize(props)}

  background: ${props => props.primary ? props.theme.colors.blue : 'none'};
  &:hover {
    background: ${props => props.primary ? props.theme.colors.blueAlt :  props.theme.colors.gray };
    color: ${props => props.theme.colors.black};
    cursor: pointer;
  }

  ${props => props.light ? `
    text-align: left;
    text-decoration: underline;
    padding: 0px;
    border: none;

    @media screen and (min-width: ${props.theme.awesomegrid.container.sm }em) {
      padding: 8px 16px;
      text-decoration: none;
      text-align: center;
      border: ${props.primary ? 'none': '2px solid' + props.theme.colors.gray};
      border-radius: ${props.theme.spacing.borderRadius.default}px;
    }` : `
    padding: 8px 16px;
    text-decoration: none;
    text-align: center;
    border: ${props.primary ? 'none': '2px solid' + props.theme.colors.gray};
    border-radius: ${props.theme.spacing.borderRadius.default}px;
  `}
`;

export const Button = ({ label, children, ...rest }) => <StyledButton {...rest}>{label || children}</StyledButton>;

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * Determines whether to show as link on mobile?
   */
  light: PropTypes.bool,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf([Sizes.wide, Sizes.short]),
  /**
   * Button contents
   */
  label: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  primary: true,
  light: false,
  size: Sizes.wide,
  small: false,
  onClick: () => {},
};

export default Button;
