import React, { createContext, useContext, useReducer} from 'react';

export const UserContext = createContext(null);
export const UserDispatchContext = createContext(null);

const formatStore = (userStore) => {
  if (userStore) {
    return userStore;
  } else {
    return {};
  }
}

export const CurrentUserProvider = ({ user, children }) => {
  const [userStore, dispatch] = useReducer(userReducer, user, formatStore);

  return (
    <UserContext.Provider value={userStore}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

const userReducer = (userStore, action) => {
  switch (action.type) {
    case 'login': {
      return {
        ...action.user
      }
    }
    case 'login_out': {
      return {}
    }
    case 'changed': {
      return {
        ...userStore,
        ...action.user
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export const useCurrentUserData = () => {
  return useContext(UserContext);
}

export const useUserDispatch = () => {
  return useContext(UserDispatchContext);
}
