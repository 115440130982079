import { useState, useEffect } from 'react';

export const useData = (url, { isText } = {isText: false}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (url) {
      let ignore = false;
      fetch(url)
        .then(response => isText ? response.text() : response.json())
        .then(data => {
          if (!ignore) {
            setData(data);
          }
        });
      return () => {
        ignore = true;
      };
    }
  }, [url]);

  return data;
}

export const useDataGetter = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (url) {
      let ignore = false;
      setLoading(true);
      fetch(url)
        .then(response => response.json())
        .then(json => {
          if (!ignore) {
            setData(json);
            setLoading(false);
            setUrl(null);
          }
        });
      return () => {
        ignore = true;
      };
    }
  }, [url]);

  return [loading, data, setUrl];
}

export const useGetData = (url) => {
  const [loading, setLoading] = useState(false);
  const [demand, setDemand] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (url && demand) {
      let ignore = false;
      setLoading(true);
      fetch(url, {
          method: "GET",
          headers: {
            "Accept": "application/json"
          }
        })
        .then(response => response.json())
        .then(json => {
          if (!ignore) {
            setData(json);
            setDemand(false);
            setLoading(false);
          }
        });
      return () => {
        ignore = true;
      };
    }
  }, [demand]);

  return [loading, data, setDemand];
}

export const usePostData = (url, { isFile, isForm } = {isFile: false, isForm: false}) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (url && payload) {
      const CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').content;
      let ignore = false;
      setLoading(true);
      fetch(url, {
          method: "POST",
          headers: {
            'Content-Type': (isFile ? 'multipart/form-data' : (isForm ? "application/x-www-form-urlencoded" : "application/json")),
            "Accept": "application/json",
            'X-CSRF-Token': CSRF_TOKEN
          },
          body: payload,
        })
        .then(response => response.json())
        .then(json => {
          if (!ignore) {
            setData(json);
            setPayload(null);
            setLoading(false);
          }
        });
      return () => {
        ignore = true;
      };
    }
  }, [payload]);

  return [loading, data, setPayload];
}

export const usePutData = (url, { isFile, isForm } = {isFile: false, isForm: false}) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (url && payload) {
      const CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').content;
      let ignore = false;
      setLoading(true);
      fetch(url, {
          method: "PUT",
          headers: {
            'Content-Type': (isFile ? 'multipart/form-data' : (isForm ? "application/x-www-form-urlencoded" : "application/json")),
            "Accept": "application/json",
            'X-CSRF-Token': CSRF_TOKEN
          },
          body: payload,
        })
        .then(response => {
          if (response.ok && response.statusText === 'No Content'){
            return JSON.parse("{}");
          } else {
            return response.json();
          }
        })
        .then(json => {
          if (!ignore) {
            setData(json);
            setPayload(null);
            setLoading(false);
          }
        });
      return () => {
        ignore = true;
      };
    }
  }, [payload]);

  return [loading, data, setPayload];
}

export const useDeleteData = (url, { isFile, isForm } = {isFile: false, isForm: false}) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(null);
  const [data, setData] = useState(null);

  const defaultPayload = (isForm ? (new URLSearchParams()).toString() : (isFile ? new FormData() : JSON.stringify({})));

  const handleDeleteRequest = (payload = defaultPayload) => {
    setPayload(payload)
  }

  useEffect(() => {
    if (url && payload) {
      const CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').content;
      let ignore = false;
      setLoading(true);
      fetch(url, {
          method: "DELETE",
          headers: {
            'Content-Type': (isFile ? 'multipart/form-data' : (isForm ? "application/x-www-form-urlencoded" : "application/json")),
            "Accept": "application/json",
            'X-CSRF-Token': CSRF_TOKEN
          },
          body: payload,
        })
        .then(response => {
          if (response.ok && response.statusText === 'No Content'){
            return JSON.parse("{}");
          } else {
            return response.json();
          }
        })
        .then(json => {
          if (!ignore) {
            setData(json);
            setPayload(null);
            setLoading(false);
          }
        });
      return () => {
        ignore = true;
      };
    }
  }, [payload]);

  return [loading, data, handleDeleteRequest];
}
