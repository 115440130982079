import { useCurrentUserData } from '../Contexts/UserContext';

export const useCurrentUserChecker = () => {
  const user = useCurrentUserData();
  const userLoggedIn = !!(user && user.id);

  const checkUserLoggedIn = () => {
    if (!userLoggedIn) {
      window.location = '/users/sign_in'
    }
  }

  return checkUserLoggedIn;
}

export const useUserLoggedIn = () => {
  const user = useCurrentUserData();

  return !!(user && user.id);
}
