import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-awesome-styled-grid';
import {useFloating, autoUpdate, FloatingArrow, arrow, offset} from '@floating-ui/react';
import { useTheme } from 'styled-components';
import PartyBox from '../PartyBox';

const ARROW_HEIGHT = 7;
const GAP = 2;

const InputField = ({ name, label, text, checkbox, errors, ...rest }) => {
  const theme = useTheme();

  const arrowRef = useRef(null);
  const {refs, floatingStyles, context} = useFloating({
    placement: 'right',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(ARROW_HEIGHT + GAP),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  return (
    <>
    { label && !checkbox && <Row>
        <Col><label htmlFor={name} >{label}</label></Col>
      </Row>
    }
    <Row ref={refs.setReference}>
      {
        checkbox && label ? <Col style={{display: 'inline'}} justify='center'>
        <input name={name} {...rest} type='checkbox'></input>
        <label htmlFor={name} >{label}</label>
      </Col> :<Col >
        {text ? <textarea name={name} {...rest} style={{width: '100%', height: '150px'}}></textarea> :  <input name={name} {...rest} ></input>}
      </Col>
      }
      
    </Row>
   { errors.length > 0 ? <PartyBox ref={refs.setFloating} style={floatingStyles}>
      <FloatingArrow ref={arrowRef} context={context} fill={theme.colors.red} />
      <ul>
        {errors.map(errorMsg => <li>{errorMsg}</li>)}
      </ul>
    </PartyBox> : "" }
    </>
  )
};

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.bool,
  checkbox: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
};

InputField.defaultProps = {
  text: false,
  checkbox: false,
  errors: []
};

export default InputField;
