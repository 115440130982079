import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const PartyBox = styled.div`
  background: var(--core-gradient, linear-gradient(270deg, #00F 0%, #F00 100%));
  border-radius: 4px;
  padding: 10px 12px;

  color: ${props => props.theme.colors.text };

  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.body}px;
  line-height: ${props => props.theme.typography.size.body * 1.5}px;
`;

PartyBox.propTypes = {}

PartyBox.defaultProps = {}

export default PartyBox;
