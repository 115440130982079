
import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Hidden } from 'react-awesome-styled-grid';

import IconLink from '../../../components/Link/IconLink';
import InternalLink from '../../../components/Link/InternalLink';
import AuthorCard from '../../Users/Cards/AuthorCard';
import { useCurrentUserData } from '../../Users/Contexts/UserContext';
import DropdownMenu, { MenuItemTypes } from '../../../components/DropdownMenu';
import { useCommentDeleter, useCommentReplies, useCommentReplyLoader, useCommentVoter } from '../hooks/useCommentActions';
import { useUserLoggedIn } from '../../Users/hooks/useCurrentUser';
import { useCommentForm } from '../hooks/useCommentFormModal';
import { IconButton, InternalButton } from '../../../components/Icons';

const CommentCard = (props) => {
  const {
    id, body, replies_count, upvotes, voted, author_username, author_joined_at, author_image_url
  } = props;

  const user = useCurrentUserData();
  const userLoggedIn = useUserLoggedIn();

  const loadReplies = useCommentReplyLoader(id); // We might need to look into pagination
  const voteForComment = useCommentVoter(id);
  const deleteComment = useCommentDeleter(id);
  

  const { formModal, editComment, addReplyComment } = useCommentForm({ commentId: id, commentBody: body })

  const replies = useCommentReplies(id);

  return <>
    {formModal}
    <Row id={`comment_${id}`}>
      <Col xs={6}>
        <Row align='center'>
          <AuthorCard username={author_username} picture={author_image_url} joined_at={author_joined_at} />
          {author_username === user.username ? <DropdownMenu 
            icon='more'
            items={ [
              [MenuItemTypes.callback, editComment, "Edit"],
              [MenuItemTypes.divider],
              [MenuItemTypes.callback, deleteComment, "Delete"],
            ]}
            /> : ''}
        </Row>
        <Row><p>{body}</p></Row>
        <Row justify='start'>
          <IconButton name='arrow_up' active={voted} onClick={voteForComment} style={{paddingRight: '16px'}}> {upvotes}</IconButton>
          <IconButton name='comments' onClick={loadReplies} style={{paddingRight: '16px'}}> {replies_count}<Hidden xs> Comment(s)</Hidden></IconButton>
          <InternalButton label="reply"  primary={!userLoggedIn} onClick={addReplyComment} />
        </Row>
      </Col>
      <Col xs={6} style={{paddingLeft: '26px', marginTop: '14px'}} >
        {replies.map(reply => reply ? <CommentCard key={`comment_${reply.id}`} {...reply}/> : '')}
      </Col>
    </Row>
  </>;
};

CommentCard.propTypes = {
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  replies_count: PropTypes.number.isRequired,
  upvotes: PropTypes.number.isRequired,
  voted: PropTypes.bool,
  author_username: PropTypes.string.isRequired,
  author_joined_at: PropTypes.string.isRequired,
  author_image_url: PropTypes.string.isRequired
};

CommentCard.defaultProps = {
  voted: false,
}

export default CommentCard;