import { useState, useEffect } from "react";
import { useToastAdd } from "../pages/Navigation/Contexts/ToastContext";

export const useToastSetter = (msg, expiration = 0) => {
  const [toast, setToast] = useState(msg);
  const addToast = useToastAdd();

  const handleSetToast = (toastData) => {
    setToast(toastData);
  };

  useEffect(() => {
    if (toast && expiration > 0){
      addToast(toast, expiration);
      setToast();
    } else if (toast) {
      addToast(toast);
      setToast();
    }
  }, [toast])

  return handleSetToast;
}
