
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Hidden } from 'react-awesome-styled-grid';

import ExternalLink from '../../../components/Link/ExternalLink';
import Button from '../../../components/Button';
import Caption from '../../../components/Caption';
import IconLink from '../../../components/Link/IconLink';
import Disclaimer from '../../../components/Disclaimer';
import { IconButton } from '../../../components/Icons';
import useIsMobile from '../../../hooks/useMobile';
import { useCollapse } from 'react-collapsed';
import Box from '../../../components/Box';
import DropdownMenu, { MenuItemTypes } from '../../../components/DropdownMenu';
import CopyText from '../../../components/CopyText';

export const Modes = {
  normal: 'normal',
  user: 'user',
  editor: 'editor',
  editorMobile: 'editor-mobile',
  highlight: 'highlight',
  heading: 'heading'
};

const NewsAccordion = styled(Box)`
  margin-top: 1em;
  padding: 1em;
`;

const ImgageDiv = styled.div`
  height: ${ props => props.mode === Modes.highlight ? '158px' : '72px'};
  width: ${ props => props.mode === Modes.highlight ? '100%' : '72px'};
  @media screen and (min-width: ${ props => props.theme.awesomegrid.container.sm }em) {
    height: ${ props => props.mode === Modes.highlight ? '158px' : (props.mode === Modes.editor ? '96px' : '144px')};
    width: ${ props => props.mode === Modes.highlight ? '100%' : (props.mode === Modes.editor ? '96px' : '144px')};
  }

  border-radius: 12px;
  border: 1px solid ${ props => props.theme.colors.gray};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("${props => props.bg}");
  background-color: #A253AF;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  ${props => props.centered ? 'align-items: center;' : ''}
  ${props => props.padTop ? 'padding-top: 8px;' : ''}
`;
FlexRow.propTypes = {
  centered: PropTypes.bool,
  padTop: PropTypes.bool,
}
FlexRow.defaultProps = {
  centered: false,
  padTop: false,
}

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  ${props => props.centered ? 'align-items: center;' : ''}
`;
FlexCol.propTypes = {
  centered: PropTypes.bool,
}
FlexCol.defaultProps = {
  centered: false,
}

const CardWrapper = styled.div`
  ${props => props.heading ? '' : (props.tiny ? 'margin-bottom: 24px;' : 'margin-bottom: 36px;')}
  min-width: 100%;
`;

CardWrapper.propTypes = {
  tiny: PropTypes.bool,
  heading: PropTypes.bool,
}
CardWrapper.defaultProps = {
  tiny: false,
  heading: false,
}

const CardHeader = (props) => {
  const { title, link, source, mode } = props;

  return <ExternalLink label={title} href={link} src={source} {...([Modes.editor, Modes.editorMobile].includes(mode) ? {secondary: true}: {})} />;
}

const CardImage = (props) => {
  const { thumbnail, mode } = props;

  return <ImgageDiv bg={thumbnail} mode={mode} />;
}

const CardTimestamp = (props) => {
  const { mode, timestamp } = props;

  return mode === Modes.editor ? <Disclaimer style={{paddingTop: '8px'}}>Generated {timestamp}</Disclaimer> :
    <Caption right padRight>Generated {timestamp}</Caption>;
}

const CardSummaryBtn = (props) => {
  const { expandAccordion, setAccordionContent, setAccordionTitle, summary } = props;

  const handleOnClick = () => {
    setAccordionTitle("TLDR; Summary");
    setAccordionContent(<div dangerouslySetInnerHTML={{__html: summary}} />);
    expandAccordion();
  }

  return <Button label="TLDR; Summary" light onClick={handleOnClick} primary={false} size="short" small />;
}

const CardFooter = (props) => {
  const {
    id, mode, popularity, comments_count, voted, bookmarked, reported,
    onUpvote, onBookmark, onReport, onUnReport,
  } = props;

  const handleUpvote = (e) => {
    e.preventDefault();
    onUpvote(id);
  };

  const handleBookmark = (e) => {
    e.preventDefault();
    onBookmark(id);
  };

  const handleReport = (e) => {
    e.preventDefault();
    onReport(id);
  };

  const handleUnReport = (e) => {
    e.preventDefault();
    onUnReport(id);
  };

  const { expandAccordion, setAccordionContent, setAccordionTitle, url } = props;

  const handleShare = (e) => {
    e.preventDefault();
    setAccordionTitle("Share");
    setAccordionContent(<><br/><CopyText text={url}/></>);

    expandAccordion();
  }

  return <FlexRow style={{marginTop: '10px'}}>
    <div>
      <IconButton name='arrow_up' padRight active={voted} onClick={handleUpvote}  style={{paddingRight: '6px'}}> {popularity}<Hidden xs sm>{ mode !== Modes.editor && ' Upvotes '}</Hidden></IconButton>
      <IconButton name={bookmarked ? 'bookmark_selected' : 'bookmark_unselected'} style={{paddingRight: '6px'}} padRight active={bookmarked} onClick={handleBookmark}><Hidden xs sm>{ mode !== Modes.editor && ' Bookmark'}</Hidden></IconButton>
      <IconButton name='share' onClick={handleShare}><Hidden xs sm>{ mode !== Modes.editor && ' Share '}</Hidden></IconButton>
    </div>
    <DropdownMenu 
      icon='more'
      items={ [
        (reported ? [MenuItemTypes.callback, handleUnReport, "Unreport Article"] : [MenuItemTypes.callback, handleReport, "Report Article"])
      ]}
      />
  </FlexRow>;
};

const CardLayout = (props) => {
  const { mode } = props;

  const isMobile = useIsMobile();

  if (mode === Modes.heading) {
    return <CardHeader {...props} />;
  } else if (mode === Modes.editor) {
    return <FlexCol>
      <CardHeader {...props} />
      <FlexRow style={{marginBottom: '8px'}}>
        <FlexCol>
          <CardTimestamp {...props} />
          <CardSummaryBtn {...props} />
        </FlexCol>
        <div><CardImage {...props}/></div>
      </FlexRow>
      <CardFooter {...props} />
  </FlexCol>
  } else if (mode === Modes.highlight) {
    return <FlexCol>
      <CardImage {...props}/>
      <FlexCol>
      <CardHeader {...props} />
        <FlexRow centered padTop>
          <CardSummaryBtn {...props} />
          <CardTimestamp {...props} />
        </FlexRow>
        <CardFooter {...props} />
      </FlexCol>
  </FlexCol>;
  } else if (mode == Modes.user) {
    return <FlexCol>
    <CardHeader {...props} />
    <CardFooter {...props} />
  </FlexCol>;
  } else {
    if(isMobile) {
      return <FlexCol>
      <FlexRow>
          <div><CardHeader {...props} /></div>
          <div><CardImage {...props}/></div>
      </FlexRow>
      <FlexRow centered>
        <CardSummaryBtn {...props} />
        <CardTimestamp {...props} />
      </FlexRow>
      <CardFooter {...props} />
    </FlexCol>;
    } else {
      return <FlexRow>
        <FlexCol>
            <div><CardHeader {...props} /></div>
            <FlexRow centered>
              <CardSummaryBtn {...props} />
              <CardTimestamp {...props} />
            </FlexRow>
            <CardFooter {...props} />
        </FlexCol>
        <div><CardImage {...props}/></div>
      </FlexRow>;
    }
  }
}

const NewsCard = (props) => {
  const { id, mode } = props;
  const [accordionTitle, setAccordionTitle] = useState();
  const [accordionContent, setAccordionContent] = useState();

  const { getCollapseProps, isExpanded, setExpanded  } = useCollapse({});

  return <CardWrapper id={`${mode}_${id}`} tiny={mode===Modes.editor} heading={mode===Modes.heading}>
   <CardLayout {...props} expandAccordion={() => setExpanded(true)} setAccordionTitle={setAccordionTitle} setAccordionContent={setAccordionContent} />
   { Modes.heading !== mode ? <div {...getCollapseProps()}>
      <NewsAccordion>
        <FlexRow>
          <h4>{accordionTitle}</h4>
          <IconButton onClick={() => setExpanded(!isExpanded)} name='close' />
        </FlexRow>
        {accordionContent}
      </NewsAccordion>
    </div> : ''}
  </CardWrapper>;
};

NewsCard.propTypes = {
  mode: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  upvotes: PropTypes.number.isRequired,
  popularity: PropTypes.number.isRequired,
  comments_count: PropTypes.number.isRequired,
  archive: PropTypes.bool.isRequired,
  url: PropTypes.string,
  voted: PropTypes.bool,
  bookmarked: PropTypes.bool,
  reported: PropTypes.bool,
  onUpvote: PropTypes.func,
  onBookmark: PropTypes.func,
  onReport: PropTypes.func,
};

NewsCard.defaultProps = {
  mode: Modes.normal,
  voted: false,
  bookmarked: false,
  reported: false,
  url: '',
  onUpvote: () => {},
  onBookmark: () => {},
  onReport: () => {},
  onUnReport: () => {},
}

export default NewsCard;
