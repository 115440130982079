import { useEffect, useState } from "react";
import { useCommentsData, useCommentsDispatch, useRepliesData } from "../Contexts/NewsCommentsContext";
import { useDeleteData, useGetData, usePostData } from "../../../hooks/useData";
import { useUserLoggedIn } from "../../Users/hooks/useCurrentUser";

export const useCommentReplyLoader = (commentId) => {
  const [page, setPage] = useState(0);
  const dispatch = useCommentsDispatch();
  const [_loading, newsComments, fetchComments] = useGetData(`/comments/${commentId}/replies?page=${page}`);
  
  const handleLoadMore = () => {
    fetchComments(true);
  }

  useEffect(() => {
    if(newsComments && !newsComments.errors) {
      dispatch({ type: 'changed', comment: { id: commentId, expanded: true }}) // Ensure parent state is set to expanded
      newsComments.map(comment => dispatch({ type: 'added', comment: comment }));
      setPage(page + 1);
    } else if (newsComments && newsComments.errors) {
      console.error(newsComments.errors);
    }
  }, [newsComments]);
  
  return handleLoadMore;
}

export const useCommentReplies = (commentId) => {
  const commentsData = useCommentsData();
  const [replyIds, setReplyIds] = useState([]); 
  const repliesData = useRepliesData();
  const [replies, setReplies] = useState([]);

  // Update comment reply Ids
  useEffect(() => {
    if(commentsData) {
      const comment = commentsData[commentId];
      if (comment && comment.replyIds) {
        setReplyIds(comment.replyIds);
      }
    }
  }, [commentsData])

  // Update the reply objects
  useEffect(() => {
    if(replyIds && repliesData) {
      setReplies(replyIds.map(replyId => repliesData[replyId]));
    }
  }, [replyIds, repliesData])

  return replies;
}

export const useCommentVoter = (commentId) => {
  const dispatch = useCommentsDispatch();
  const userLoggedIn = useUserLoggedIn();
  const [_voting, voteData, upvote] = usePostData(`/comments/${commentId}/vote`);

  useEffect(() => {
    if(voteData && voteData.success) {
      dispatch({ type: 'changed', comment: voteData.comment });
    }
  }, [voteData]);

  const handleVote = () => {
    if (userLoggedIn) {
      upvote(JSON.stringify({}));
    }
  }

  return handleVote;
}

export const useCommentDeleter = (commentId) => {
  const dispatch = useCommentsDispatch();
  const userLoggedIn = useUserLoggedIn();
  const [_deletion, deleteData, deleteComment] = useDeleteData(`/comments/${commentId}`);

  useEffect(() => {
    if(deleteData && deleteData.success) {
      dispatch({ type: 'deleted', comment: deleteData.comment });
    }
  }, [deleteData]);

  const handleDelete = () => {
    if (userLoggedIn) {
      deleteComment();
    }
  }

  return handleDelete;
}
