import React from 'react';
import styled from 'styled-components';
import loaderSvg from './loader.svg';

const StyledImg = styled.img`
  animation-name: ckw;
  animation-duration: 2s;
  /* Things added */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
  display: inline-block;
  /* <--- */

  @keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
`;

const Loader = ({ ...rest }) => <StyledImg
    {...rest}
    src={loaderSvg}
    alt="Loading spinner"
  />;


export default Loader;