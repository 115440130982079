export const darkTheme = {
  colors: {
    background: '#000000',
    black: '#000000',
    blue: '#6161FF',
    blueAlt: '#4242FF',
    gray: '#929292',
    red: '#FF0000',
    yellow: '#FFFF00',
    yellowAlt: '#AF9128',
    white: '#FFFFFF',
    text: '#FFFFFF',
    textAlt: '#000000',
  },
  spacing: {
    padding: {
      small: 10,
      medium: 20,
      large: 30,
    },
    borderRadius: {
      default: 8,
    },
  },
  typography: {
    type: {
      default: '"Poppins", sans-serif',
      code: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
    },
    weight: {
      bold: '600',
      regular: '400',
      small: '300',
    },
    size: {
      header_1: 54,
      header_2: 36,
      header_3: 24,
      header_4: 16,
      body: 14,
      caption: 12,
      disclaimer: 10
    },
  },
  awesomegrid: {
    gutterWidth: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0,
    },
    paddingWidth: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0,
    },
    container: {
      xs: 25, // max-width: 400px
      sm: 48, // max-width: 768px
      md: 63, // max-width: 1008px
      lg: 63, // max-width: 1008px
      xl: 63, // max-width: 1008px
    },
    breakpoints: {
      xs: 1,
      sm: 48, // 768px
      md: 63, // 1008px
      lg: 63, // 1008px
      xl: 63, // 1008px
    },
    columns: {
      xs: 6,
      sm: 6,
      md: 6,
      lg: 6,
      xl: 6,
    },
  }
};

export const lightTheme = {
  ...darkTheme,
    colors: {
    // background: '#1b1c1d',
    // backgroundInverse: '#333333',
    // positive: '#9fd986',
    // negative: '#df987d',
    // primary: '#d43369',
    // secondary: '#1b8bd0',
    // tertiary: '#DDDDDD',
    // text: '#FFFFFF',
  },
};