

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'
import useIconSvg from './svgs';

export const Icon = styled.span`
  ${props => props.heading ? `
    width: 22.77px;
    height: 24px;
  `: `
    width: 17px;
    height: 17px;
  `}
  display: inline-block;
  margin-bottom: -3px;
  color: inherit;
  background-color: currentColor;
  -webkit-mask: url(${props => useIconSvg(props.name)}) no-repeat 50% 50%;
  mask: url(${props => useIconSvg(props.name)}) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
`;

Icon.propTypes = {
  /**
   * The name of the icon to render
   */
  name: PropTypes.string.isRequired,
  heading: PropTypes.bool,
};

Icon.defaultProps = {
  heading: false,
};

const StyledButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0px 0px;
  margin: 0px 0px;
  color: ${props => props.active ? props.theme.colors.yellowAlt : props.theme.colors.text };
  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.caption}px;
  line-height: ${props => props.theme.typography.size.caption * 1.5}px;
  
`;

export const IconButton = ({children, name, ...rest}) => <StyledButton {...rest} ><Icon name={name} />{children}</StyledButton>
IconButton.propTypes = {
  /**
   * The name of the icon to render
   */
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};
IconButton.defaultProps = {
  active: false,
}

const fontSizeForVariant = (props) => {
  if(props.primary) {
    return `
      font-weight:  ${props => props.theme.typography.weight.bold};
      font-size: ${props => props.theme.typography.size.header_4}px;
      line-height: ${props => props.theme.typography.size.header_4 * 1.5}px;
    `
  } else {
    return `
    font-weight:  ${props => props.theme.typography.weight.regular};
    font-size: ${props => props.theme.typography.size.body}px;
    line-height: ${props => props.theme.typography.size.body * 1.5}px;
  `
  }
}

const StyledInternalButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0px 0px;
  margin: 0px 0px;
  color: ${props => props.primary ?  props.theme.colors.gray : props.theme.colors.blue};

  font-family: ${props => props.theme.typography.type.default};
  ${props => fontSizeForVariant(props)}

  &:hover, &:active {
    text-decoration: none;
    background: none;
    cursor: pointer;
    color: ${props => props.primary ?  props.theme.colors.gray : props.theme.colors.blue};
  }
`;

export const InternalButton = ({ label, ...rest }) => <StyledInternalButton {...rest}>{label}</StyledInternalButton>;

InternalButton.propTypes = {
  /**
   * The link body
  */
  label: PropTypes.string.isRequired,
  /**
   * Indicator of whether to use primary styling or secondary
   */
  primary: PropTypes.bool,
};

InternalButton.defaultProps = {
  primary: true,
};

export default Icon;
