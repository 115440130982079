import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';

import NewsCard, { Modes } from '../Cards/NewsCard';
import Loader from '../../../components/Loader';

import { useLatestNews, useArchiveNews, useTopPickNews, useUserNews, useNewsDispatch} from '../Contexts/NewsContext';
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import { useData, useGetData, usePostData } from '../../../hooks/useData';
import { Container, Row } from 'react-awesome-styled-grid';
import { useNewsUpvote, useNewsBookmark, useNewsReport, useNewsUndoReport } from '../hooks/useNewsActions';
// import { useNewsUpdates } from '../hooks/useNewsUpdates';

const NewsList = ({ type }) => {
  const loadMoreRef = useRef(null);
  const pageCounter = useRef(1);
  const [contentAvailable, setContentAvailable] = useState(!['top_picks', 'user'].includes(type));
  const [nextPageUrl, setNextPageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const isAtListEnd = useIntersectionObserver(loadMoreRef);
  // useNewsUpdates();

  const dispatch = useNewsDispatch();

  const newNewsData = useData(nextPageUrl)

  useEffect(() => {
    if (newNewsData) {
      if (newNewsData.length === 0) { setContentAvailable(false); }

      dispatch({ type: 'added', news: newNewsData });

      setLoading(false);
    }
  }, [newNewsData])

  useEffect(() => {
    if(isAtListEnd && contentAvailable) {
      setLoading(true);
      pageCounter.current = pageCounter.current + 1;
      setNextPageUrl(`/news.json?${type === 'latest' ? 'news_page' : 'archives_page'}=${pageCounter.current}`);
    };
  }, [isAtListEnd])

  const newsItems = type === 'latest' ? useLatestNews() : (
    type === 'archive' ? useArchiveNews() : (
      type === 'user' ? useUserNews() : useTopPickNews()
  ));

  const upvoteNews = useNewsUpvote();
  const bookmarkNews = useNewsBookmark();
  const reportNews = useNewsReport();
  const unreportNews = useNewsUndoReport();

  return <Container>
      <Row>
        {
          newsItems.map((news, index) => (
            <NewsCard {...news}
              key={`${type}-${news.id}`} 
              mode={ (type === 'user') ? Modes.user : ((type === 'top_picks_tab') ?  Modes.editorMobile : (
                type === 'top_picks' ? Modes.editor : (index === 0 ? Modes.highlight : Modes.normal)
              ))}
              onUpvote={upvoteNews}
              onBookmark={bookmarkNews}
              onReport={reportNews}
              onUnReport={unreportNews}
              />
          ))
        }
      </Row>
      <Row ref={loadMoreRef} align='center' justify='center'>{loading ? <Loader /> : '' }</Row>
    </Container>
}

NewsList.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NewsList;
