import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Hidden } from 'react-awesome-styled-grid';

import Caption from '../../../components/Caption';
import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import InternalLink from '../../../components/Link/InternalLink';

import { usePostData } from '../../../hooks/useData';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Uinput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`

const LoginForm = ({ onSignIn, errors }) => {
  const [loading, userData, logInUser] = usePostData('/users/sign_in', { isForm: true });
  const [loginErrors, setLoginErrors] = useState(errors);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    var urlEncodedData = new URLSearchParams(formData);

    logInUser(urlEncodedData.toString());
  }

  useEffect(() => {
    if (userData) {
      onSignIn(userData);
      if (userData.error) {
        setLoginErrors([userData.error])
      }
    }
  }, [userData]);

  return <Col xs={6}>
      <Row justify='center'>
        <Col align='center'><h3>Sign in to your account</h3></Col>
      </Row>
      <Row justify='center'>
        <Col align='center'>{loginErrors.map(errorMsg => <p className='danger'><i>* {errorMsg}</i></p>)}</Col>
      </Row>
      <Row justify='center'>
        <Col xs={5} sm={4} md={4} lg={3}>
          <form id="new_user" onSubmit={handleSubmit}>
              <Row>
                <Col><label htmlFor="user[login]">Username or Email</label></Col>
              </Row>
              <Row>
                <Col><input name='user[login]' autoFocus={true} autoComplete='login'></input></Col>
              </Row>
              <Row>
                <Col><label htmlFor="user[password]">Password</label></Col>
              </Row>
              <Row>
                <Col><input name='user[password]' type='password' autoComplete='current_password'></input></Col>
              </Row>
              <Row align='center'>
                <Col style={{display: 'inline'}} justify='center'>
                  <input name='user[remember_me]' type='checkbox'></input>
                  <label htmlFor="user[remember_me]">Remember Me</label>
                </Col>
              </Row>
              <Uinput name='is_humann' placeholder='Humann' type='checkbox' />
              <Row>
                <Button type='submit' name="commit">SIGN IN</Button>
              </Row>
              <br />
              <Row justify='center'>
                <Col align='center'><Caption>Your Privacy is important to us. Your info is YOURS and we will not ever sell it</Caption></Col>
              </Row>
              <br />
              <Row>
                <Col><InternalLink label="Forgot Password" primary={false} href='/users/password/new' /></Col>
              </Row>
              <Row>
                <Col><InternalLink label="Resend confirmation Email" primary={false} href='/users/confirmation/new' /></Col>
              </Row>
              <br />
              <Divider gradient={false} />
              <br />
              <Row  justify='center'><h3>Create an Account</h3></Row>
              <Row>
                <Button label="SIGN UP" onClick={(e) => {
                  e.preventDefault();
                  window.location = '/users/sign_up';
                }} primary={false}/>
              </Row>
              <Row justify='center'>
                <p style={{textAlign: 'center'}}>By Signing In, You will be able to Up Vote, Comment, Bookmark Control your Profile, and Change the Writing Style of the Article you Read.</p>
              </Row>
              <br />
          </form>
        </Col>
      </Row>
    </Col>;
};

LoginForm.propTypes = {
  onSignIn: PropTypes.func,
  errors: PropTypes.array,
};

LoginForm.defaultProps = {
  onSignIn: () => {},
  errors: [],
};

export default LoginForm;