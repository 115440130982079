import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-awesome-styled-grid';

import Button from '../../../components/Button';
import SectionHeader from '../../../components/SectionHeader';

import { usePostData } from '../../../hooks/useData';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField';
import styled from 'styled-components';
import FloatingDiv from '../../../components/FloatingDiv';

const Title = styled.h1`
  margin-top: 96px;
  text-align: center;
`

const Description = styled.h3`
  text-align: center;
`

const RedBar = styled(Col)`
  background: ${props => props.theme.colors.red};
  height: 20px;
`
const YellowBar = styled(Col)`
  background: ${props => props.theme.colors.yellow};
  height: 20px;
`
const BlueBar = styled(Col)`
  background: ${props => props.theme.colors.blue};
  height: 20px;
`

const Uinput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`

const RegistrationForm = ({ onSignUp, errors }) => {
  const [loading, userData, createUser] = usePostData('/users', { isForm: true });
  const [registrationErrors, setRegistrationErrors] = useState(errors);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    var urlEncodedData = new URLSearchParams(formData);

    createUser(urlEncodedData.toString());
  }

  useEffect(() => {
    if (userData) {
      onSignUp(userData);
      if (userData.errors) {
        setRegistrationErrors(userData.errors)
      }
    }
  }, [userData]);

  return <Col xs={6}>
      <Row justify='center'>
        <Title>Your source for everything humans are doing with AI</Title>
      </Row>
      <br />
      <Row justify='center'>
        <Col xs={5}><Description>By Signing Up, You will be able to Up Vote, Comment, Bookmark Control your Profile, and Change the Writing Style of any Article you Read.</Description></Col>
      </Row>
      <br />
        <FloatingDiv height={20}>
          <Row>
            <RedBar />
            <YellowBar />
            <BlueBar />
          </Row>
        </FloatingDiv>
      <br /><br />
      <Row justify='flex-start'>
        <Col xs={5} sm={4} md={3} lg={2}><SectionHeader label={'And It’s Free to Join'} gradient /></Col>
      </Row>
      <Row justify='flex-start'>
        <Col xs={5} sm={4} md={4} lg={3}>
          <form id="new_user" onSubmit={handleSubmit}>
            <InputField errors={registrationErrors['username']}
               name='user[username]' label='Username' aria-required required autoComplete='username' />
            <br />
            <InputField errors={registrationErrors['email']}
               name='user[email]' label='Email' aria-required required type='email' autoComplete='email' />
            <br />
            <InputField errors={registrationErrors['password']}
              name='user[password]' label='Password'  type='password' aria-required required autoComplete='new-password' />
            <br />
            <InputField errors={registrationErrors['password_confirmation']}
              name='user[password_confirmation]' label='Password Confirmation' type='password' aria-required required autoComplete='new-password' />
            <br />
            <Row>
                <Col style={{display: 'inline'}} >
                  <input name='terms' aria-required required  type='checkbox'></input>
                  <label htmlFor="terms">By signing up, I agree to Unfated.ai’s <a href='/terms'>Terms of Use</a> and I acknowledge Unfated.ai’s Privacy statement located in our <a href='/privacy'>Privacy Center</a>.</label>
                </Col>
            </Row>
            <Uinput name='is_humann' placeholder='Humann' type='checkbox' />
            <br />
            <Row>
              <Button type='submit' name="commit">SIGN UP</Button>
            </Row>
          </form>
        </Col>
      </Row>
    </Col>;
};

RegistrationForm.propTypes = {
  onSignUp: PropTypes.func,
  errors: PropTypes.object,
};

RegistrationForm.defaultProps = {
  onSignUp: () => {},
  errors: {},
};

export default RegistrationForm;