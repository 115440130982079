import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components'
import { IconButton } from '../Icons';
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { useToastAdd } from '../../pages/Navigation/Contexts/ToastContext';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  align-items: center;
  
  span {
    padding-left: 8px;
    position: relative;
  }
`;

const CopyText = ({ text }) => {
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const addToast = useToastAdd();

  useEffect(() => {
    if (copiedText && copiedText.text) {
      addToast(`Copied ${text} to clipboard.`, 5000);
    }
  }, [copiedText]);

  return <FlexRow><IconButton name='url_link' onClick={() => copyToClipboard(text)} ><span>{text}</span></IconButton></FlexRow>
}

CopyText.propTypes = {
  /**
   * The text to be copied
  */
  text: PropTypes.string,
};

CopyText.defaultProps = {
  text: ''
};

export default CopyText;