
import { useState, useEffect } from 'react';

export const useResetter = (initial = false) => {
  const [reset, setReset] = useState(initial);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const handleReset = () => {
    setReset(true);
  }

  return [reset, handleReset];
}
