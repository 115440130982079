import React, { createContext, useContext, useRef, useState} from 'react';

export const ToastsContext = createContext(null);
export const ToastAddContext = createContext(null);
export const ToastRemoveContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const handleAddToast = (toast, expiration=0) => {
    if (!toasts.includes(toast)) {
      setToasts(Array.from(new Set([...toasts, toast])));

      if (expiration > 0) {
        setTimeout((toast) => {
          handleRemoveToast(toast);
        }, expiration, toast)
      }
    }
  }

  const handleRemoveToast = (toast) => {
    setToasts(toasts.filter((t) => t !== toast));
  }

  return (
    <ToastsContext.Provider value={toasts}>
      <ToastAddContext.Provider value={handleAddToast}>
        <ToastRemoveContext.Provider value={handleRemoveToast}>
          {children}
        </ToastRemoveContext.Provider>
      </ToastAddContext.Provider>
    </ToastsContext.Provider>
  );
}

export const useToasts = () => {
  return useContext(ToastsContext);
}

export const useToastAdd = () => {
  return useContext(ToastAddContext);
}

export const useToastRemove = () => {
  return useContext(ToastRemoveContext);
}