import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row, Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import { useNavigationTracker } from '../../hooks/useNavigationTracker';

import SectionHeader from '../../components/SectionHeader';
import IconLink from '../../components/Link/IconLink';

import { NewsCommentsProvider } from './Contexts/NewsCommentsContext';
import CommentsList from './Parts/CommentsList';
import PageLayout from '../Layout';

const BackLink = styled(Row)`
  margin: 16px auto;
`;

const NewsComments = ({ user, news, initialComments }) => {
  const prevLink = useNavigationTracker();

  return <PageLayout user={user}>
    <NewsCommentsProvider news={news} initialComments={initialComments}>
      <Container style={{paddingTop: '1em'}}>
        { prevLink ? <BackLink><IconLink name='arrow_left' href={prevLink}>Return to Previous Page</IconLink></BackLink> : '' }
        <Row>
          <Col md={2}><Row><SectionHeader label={"Comments"} /></Row></Col>
          <Col sm={1}></Col>
        </Row>
        <Row><CommentsList /></Row>
      </Container>
    </NewsCommentsProvider>
  </PageLayout>;
};

NewsComments.propTypes = {
  user: PropTypes.object,
  news: PropTypes.object.isRequired,
  initialComments: PropTypes.array.isRequired,
};
NewsComments.defaultProps = {
  user: null,
}

export default NewsComments;
