import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Hidden } from 'react-awesome-styled-grid';

import Caption from '../../../components/Caption';
import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import InternalLink from '../../../components/Link/InternalLink';

import { usePostData } from '../../../hooks/useData';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField';
import { useToastSetter } from '../../../hooks/useToast';

const ContentForm = ({ onSubmitContent, errors }) => {
  const setSuccessToast = useToastSetter(null, 5000);

  const formRef = useRef();
  const [loading, submissionData, submitContent] = usePostData('/content_submissions', { isForm: true });
  const [submisionErrors, setSubmissionErrors] = useState(errors);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    var urlEncodedData = new URLSearchParams(formData);

    submitContent(urlEncodedData.toString());
  }

  useEffect(() => {
    if (submissionData) {
      if (submissionData.errors) {
        setSubmissionErrors(submissionData.errors)
      } else {
        if(formRef) {
          formRef.current.reset();
          setSubmissionErrors([]);
          setSuccessToast("Submission Successful");
        }
        onSubmitContent(submissionData);
      }
    }
  }, [submissionData]);

  return <Col xs={6}>
  <Row justify='center'>
    <Col xs={5} sm={4} md={4} lg={4}>
      <form id="new_content_submission" onSubmit={handleSubmit} ref={formRef}>
        <br />
        <InputField name="content_submission[title]" errors={submisionErrors['title']} placeholder="Title" autoFocus={true} />
        <br />
        <InputField name="content_submission[url]" errors={submisionErrors['url']} type='url' placeholder="Content URL" aria-required required />
        <br />
        <InputField name="content_submission[tags]" errors={submisionErrors['tags']}  placeholder="Tags" />
        <br />
        <Row>
          <textarea name="content_submission[message]" errors={submisionErrors['message']}  placeholder='Your Message'style={{width: '100%'}} ></textarea>
        </Row>
        <br />
        <Row>
          <Button type='submit' name="commit">SUBMIT</Button>
        </Row>
      </form>
    </Col>
  </Row>
</Col>;
};

ContentForm.propTypes = {
  onSubmitContent: PropTypes.func,
  errors: PropTypes.array,
};

ContentForm.defaultProps = {
  onSubmitContent: () => {},
  errors: [],
};

export default ContentForm;