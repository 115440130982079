import React from 'react';
import ErrorBackground from './Error_Page_Background.svg';

import { darkTheme } from '../../theme';

import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import Logo from '../../components/Logo';
import Button from '../../components/Button';

const DivBackground = styled.div`
  background-image: url(${ErrorBackground});
  background-size:100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size:cover;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: table;

  #content {
    display: table-cell;
    text-align: center;
    vertical-align: middle;

    h3 {
      margin: 24px auto;
    }

    button {
      max-width: 512px;
    }
  }
`

const Errors = ({ status }) => {

  const statusMessage = {
    404: 'There’s nothing but alpacas here.',
    422: 'The change you wanted was rejected.',
    500: "We're sorry, but something went wrong.",
  }

  return <ThemeProvider theme={darkTheme}>
      <DivBackground>
      <div id='content'>
        <Logo symbols />
        <h1>{status} Error</h1>
        <h3>{statusMessage[status]}</h3>
        <Button label="Return to Home Page" onClick={() => window.location = '/' } />
      </div>
    </DivBackground>
  </ThemeProvider>;
};

Errors.propTypes = {
  status: PropTypes.number.isRequired,
  exception: PropTypes.any,
};

export default Errors;
