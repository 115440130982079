import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-awesome-styled-grid';

import PageLayout from '../Layout';
import styled from 'styled-components';
import Button from '../../components/Button';

const Hero = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background: ${props => props.theme.colors.white };
  padding-top: 176px;

  h1 {
    color: ${props => props.theme.colors.black };
    max-width: 984px;
    padding-bottom: 94px;
  }
`;

const LoHero = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background: ${props => props.theme.colors.yellow };
  padding: 97px 170px;

  h1 {
    color: ${props => props.theme.colors.black };
  }

  h3 {
    max-width: 776px;
    color: ${props => props.theme.colors.black };
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;

  div {
    padding: 70px 85px 25px 95px;
    width: 50vw;
    color:  ${props => props.theme.colors.text };
  }
  .black { background: ${props => props.theme.colors.black };}
  .blue { background: ${props => props.theme.colors.blue };}
`;

const About = ({ user }) => {
  return <PageLayout user={user}>
      <Hero>
        <h1>Your source for everything humans are doing with AI</h1>
      </Hero>
      <InfoRow>
        <div className='black'>
          <h3>Unfated.Ai was created with one core mission : To provide a source for everyone to have a pulse for what going on in the AI.</h3>
          <br/>
          <h3>From Pop to News we bring together content that everyone can access from multiple platforms into one hub.</h3>
          <br/>
          <h3>We’re doing it first. And we alway’s cite our sources. </h3>
        </div>
        <div className='blue'>
          <h3>Our Tenets:</h3>
          <ol>
            <h3><li>Provide Content that helps and not hurt humanity</li></h3>
            <h3><li>Credit the Creatives and great Minds behind these Innovations</li></h3>
            <h3><li>Uncover the most interesting ways A.I. is being utilizedBring transparent A.I. News to Everyone</li></h3>
            <h3><li>Allow community engagement in a safe  proactive environment</li></h3>
            <h3><li>Engage with true candor.</li></h3>
          </ol>
        </div>
      </InfoRow>
      <LoHero>
        <h1>Get More from Unfated.AI when You Join</h1>
        <h3>By Signing Up, You will be able to Up Vote, Comment, Bookmark Control your Profile, and Change the Writing Style of the Article you Read.</h3>
        <br />
        <Button label="SIGN UP TODAY" onClick={(e) => {
                  e.preventDefault();
                  window.location = '/users/sign_up';
                }} style={{width: '462px'}} />
        <br />
        <h3>If you have comments, feedback, or questions please contact us at help@unfated.ai</h3>
      </LoHero>
  </PageLayout>;
};

About.propTypes = {
  user: PropTypes.object,
};
About.defaultProps = {
  user: {},
};
export default About;
