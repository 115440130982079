import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';


const Caption = styled.span`
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.caption}px;
  line-height: ${props => props.theme.typography.size.caption * 1.5}px;
  color: ${props => props.theme.colors.text};

  ${props => props.right ? 'text-align: right;' : ''}
  ${props => props.padRight ? 'padding-right: 12px;' : ''}
`;

Caption.propTypes = {
  right: PropTypes.bool,
  padRight: PropTypes.bool,
};

Caption.defaultProps = {
  right: false,
  padRight: false,
}

export default Caption;
