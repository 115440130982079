import { useState, useEffect } from "react";

const getIsMobile = () => {
  let isMobile = false
  try {
    isMobile = window.innerWidth <= 768;
  } catch (error) {
    console.warn(error);
  }
  return isMobile;
};

export default function useIsMobile() {
    const [isMobile, setIsMobile] = useState(getIsMobile());

    useEffect(() => {
      const onResize = () => {
        setIsMobile(getIsMobile());
      }

      window.addEventListener("resize", onResize);
  
      return () => {
        window.removeEventListener("resize", onResize);
      }
    }, []);
    
    return isMobile;
}
