import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'
import { Tab } from '../react-tabs';

import { SELECTED_TAB_CLASS_NAME } from '../Tabs';

const StyledTab = styled(Tab)`
  border-bottom-style: solid;
  border-bottom-width: 8px;

  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_3}px;
  line-height: ${props => props.theme.typography.size.header_3 * 1.5}px;

  color:  ${props => props.theme.colors.gray};
  border-bottom-color: ${props => props.theme.colors.gray };

  &.${SELECTED_TAB_CLASS_NAME}, &:hover {
    cursor: pointer;
    color:  ${props => props.theme.colors.text};
    border-image: linear-gradient(270deg, #0000FF -4.15%, #FF0000 99.91%) 1;
  }
`;

StyledTab.tabsRole = 'Tab';

export const SmallTab = styled(StyledTab)`
  font-size: ${props => props.theme.typography.size.header_4}px;
  line-height: ${props => props.theme.typography.size.header_4 * 1.5}px;
`;
SmallTab.tabsRole = 'Tab';

export default StyledTab;
