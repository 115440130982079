import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import FullLogo from './full_logo.svg';
import SymbolsLogo from './symbols_logo.svg';

const StyledImg = styled.img`
  ${ props => props.footer ? 'height: 24px;' : '' }
  width: 100%;
  max-height: 64px;
`;

const Logo = ({ symbols, ...rest }) => <StyledImg
    {...rest}
    src={symbols ? SymbolsLogo : FullLogo }
    alt="Unfated.AI Logo"
  />;

Logo.propTypes = {
  /**
   * Whether to use footer version of logo or normal
  */
  footer: PropTypes.bool,
  /**
   * Whether to use symbols logo or the full logo
  */
  symbols: PropTypes.bool,
};

Logo.defaultProps = {
  footer: false,
  symbols: false,
};

export default Logo;