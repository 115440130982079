import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { TabList } from '../react-tabs';

const StyledTabList = styled(TabList)`
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-image: linear-gradient(270deg, #0000FF -4.15%, #FF0000 99.91%) 1;
  
  div:not(:last-child) {
    margin-right: 5%;
  }
`;

StyledTabList.tabsRole = 'TabList';

export default StyledTabList;
