import React from 'react';
import { Container, Row, Col } from 'react-awesome-styled-grid';

import Logo from '../../components/Logo';
import NavLink from '../../components/Link/NavLink';
import { useNavigationTracker } from '../../hooks/useNavigationTracker';
import Divider from '../../components/Divider';
import styled from 'styled-components';
import Menu from './Cards/Menu';

const TopRow = styled(Row)`
  padding-top: 12px;
`;

const Header = () => {
  useNavigationTracker();

  return <div>
    <Container>
      <TopRow justify='center'>
        <Col xs={5} sm={4} align='flex-start'>
          <NavLink href="/"><Logo /></NavLink>
        </Col>
        <Col xs={1} sm={2} align='flex-end'>
          <Menu />
        </Col>
      </TopRow>
    </Container>
    <Divider />
  </div>;
};

Header.propTypes = {};

export default Header;
