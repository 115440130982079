import React from 'react';
import styled from 'styled-components'

const StyledLink = styled.a`
  color: ${props => props.theme.colors.gray };
  background: none;
  padding: 12px 24px;
  text-decoration: none;
  text-align: center;
  border: 2px solid ${props => props.theme.colors.gray};
  border-radius: ${props => props.theme.spacing.borderRadius.default}px;
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_4}px;
  line-height: ${props => props.theme.typography.size.header_4 * 1.5}px;

  &:hover {
    background: ${props => props.theme.colors.gray };
    color: ${props => props.theme.colors.black};
    cursor: pointer;
  }
`;

export const ButtonLink = ({ children, ...rest }) => <StyledLink {...rest}>{ children}</StyledLink>;

ButtonLink.propTypes = {};

ButtonLink.defaultProps = {};

export default ButtonLink;
