import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

const borderStyle = (props) => {
  if(props.gradient) {
    return `border-image: linear-gradient(270deg, #0000FF -4.15%, #FF0000 99.91%) 1`;
  } else {
    return 'border-bottom-color:' +  props.theme.colors.red;
  }
}

export const StyledDiv = styled.div`
  min-width: 100%;
  text-align: left;

  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.header_3}px;
  line-height: ${props => props.theme.typography.size.header_3 * 1.5}px;
  color:  ${props => props.theme.colors.text};

  border-bottom-style: solid;
  border-bottom-width: 2px;
  ${ props => borderStyle(props)};
  margin-bottom: 16px;
`;

const Header = ({ label, children, ...rest }) => <StyledDiv {...rest}>{label ? label : children}</StyledDiv>;

Header.propTypes = {
  /**
   * The header content
   */
  label: PropTypes.string,
  /**
   * Whether to gradient version is enabled or not
  */
  gradient: PropTypes.bool
};

Header.defaultProps = {
  label: null,
  gradient: true,
};

export default Header;
