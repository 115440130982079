import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'

const StyledLink = styled.a`
  text-decoration: underline;
  background: 'none';
  cursor: pointer;
  color: ${props => props.active ?  props.theme.colors.yellowAlt : props.theme.colors.text};

  font-family: ${props => props.theme.typography.type.default};
  font-weight:  ${props => props.theme.typography.weight.regular};
  font-size: ${props => props.theme.typography.size.caption}px;
  line-height: ${props => props.theme.typography.size.caption * 1.5}px;

  &:hover {
    background: 'none';
    color: ${props => props.theme.colors.yellowAlt };
  }
`;

const PlainLink = ({ children, ...rest }) => <StyledLink {...rest}>{children}</StyledLink>;

PlainLink.propTypes = {};

PlainLink.defaultProps = {
  active: false
};

export default PlainLink;
